import React, { FunctionComponent, useMemo } from 'react';
import { endOfYear, startOfYear, subYears } from 'date-fns';
import { useProReviewInvitationGraphQuery } from 'generated/graphql';
import { ProReviewInvitationsGraphContainer } from './pro-review-invitations-graph-container';

const now = new Date();

type ProReviewInvitationsGraphRepositoryProps = {
  bilikZoneIds?: number[];
  tradeId?: number;
  year?: number;
  compareYear?: number;
};

export const ProReviewInvitationsGraphRepository: FunctionComponent<
  ProReviewInvitationsGraphRepositoryProps
> = ({
  bilikZoneIds,
  tradeId,
  year = now.getFullYear(),
  compareYear = subYears(now, 1).getFullYear(),
}) => {
  const { date, compareDate } = useMemo(() => {
    const date = new Date(now);
    date.setFullYear(year);

    const compareDate = new Date(now);
    compareDate.setFullYear(compareYear);

    return {
      date: date,
      compareDate: compareDate,
    };
  }, [year, compareYear]);

  const { loading, data } = useProReviewInvitationGraphQuery({
    variables: {
      where: {
        _or: [
          {
            dateCreated: {
              _gte: startOfYear(date).toISOString(),
              _lte: endOfYear(date).toISOString(),
            },
          },
          {
            dateCreated: {
              _gte: startOfYear(compareDate).toISOString(),
              _lte: endOfYear(compareDate).toISOString(),
            },
          },
        ],
        proPresentation: {
          proViews: {
            bilikZoneId: bilikZoneIds
              ? {
                  _in: bilikZoneIds,
                }
              : undefined,
            trades: {
              tradeId: tradeId ? { _eq: tradeId } : undefined,
            },
          },
        },
      },
    },
  });

  return (
    <ProReviewInvitationsGraphContainer
      loading={loading}
      proReviewInvitations={data?.proReviewInvitation}
      date={date}
      compareDate={compareDate}
    />
  );
};
