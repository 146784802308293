import { OrderBy, useSelectBilikZoneQuery } from 'generated/graphql';
import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';

interface SelectBilikZoneProps {
  onChange?: (zoneId: number) => void;
  value?: number;
  style?: React.CSSProperties;
  disabled?: boolean;
  allAuthorizedZones?: boolean;
}

export const SelectBilikZone = ({
  onChange,
  value,
  style,
  disabled,
  allAuthorizedZones,
}: SelectBilikZoneProps) => {
  const { data, loading } = useSelectBilikZoneQuery({
    fetchPolicy: 'no-cache',
    variables: {
      orderBy: [{ name: OrderBy.Asc }],
    },
  });

  const [selectedBilikZone, setSelectedBilikZone] = useState<
    number | undefined
  >(value);

  useEffect(() => {
    setSelectedBilikZone(value);
  }, [value]);

  const options: DropdownItemProps[] = useMemo(() => {
    if (data?.bilikZone) {
      const newOptions: DropdownItemProps[] = [];

      if (allAuthorizedZones) {
        newOptions.push({ value: 0, key: 0, text: 'Toutes les zones' });
      }

      data?.bilikZone.map((bilikZone) =>
        newOptions.push({
          value: bilikZone.id,
          key: bilikZone.id,
          text: bilikZone.name,
        }),
      );

      return newOptions;
    }
    return [];
  }, [data?.bilikZone]);

  return (
    <Dropdown
      style={style}
      selection
      loading={loading}
      disabled={loading || disabled}
      onChange={(_, { value }): void => {
        if (onChange) {
          onChange(Number(value));
        }
        setSelectedBilikZone(Number(value));
      }}
      value={selectedBilikZone}
      options={options || []}
      placeholder={'Selectionnez une zone...'}
    />
  );
};
