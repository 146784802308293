import { isValidFrTelephone } from 'utils/telephone';
import * as Yup from 'yup';

const telephoneReview = {
  telephone: Yup.string()
    .required("Ecrivez NULL dans le champ si vous n'avez pas le telephone")
    .test(
      'partial-telephone',
      'Entrer un telephone valide ou NULL si pas de telephone',
      async (telephoneValue) => {
        if (telephoneValue === 'NULL') {
          return true;
        }

        const isTelephone = Yup.string()
          .required()
          .test(
            'is-valid',
            'Numéro de téléphone invalide !',
            isValidFrTelephone,
          )
          .validate(telephoneValue, { strict: true })
          .then(() => true)
          .catch(() => false);

        return isTelephone;
      },
    ),
};

const emailReview = {
  email: Yup.string()
    .required("Ecrivez NULL dans le champ si vous n'avez pas l'email")
    .test(
      'partial-email',
      "Entrer un email valide ou NULL si pas d'email",
      async (emailValue) => {
        if (emailValue === 'NULL') {
          return true;
        }

        const isEmailValid = await Yup.string()
          .required()
          .email()
          .validate(emailValue, { strict: true })
          .then(() => true)
          .catch(() => false);

        return isEmailValid;
      },
    ),
};

const rating = {
  rating: Yup.number()
    .required('La note est requise !')
    .integer('La note doit correspondre à un chiffre entier entre 1 et 5')
    .max(5, 'La note doit être inférieure ou égale à 5')
    .min(1, 'La note doit être supérieure ou égale à 1'),
};

export const proReviewFormValidationSchema = (
  hasReply: boolean,
): Yup.ObjectSchema =>
  Yup.object().shape({
    reviewAspect: Yup.string().required('La nature de la prestation requise !'),
    reviewBody: Yup.string().required('Commentaire requis !'),
    comment: Yup.string(),
    reply: hasReply ? Yup.string().required('Reponse requise !') : Yup.string(),
    cityId: Yup.number().nullable().required('Lieu de la prestation requis !'),
    givenName: Yup.string().required('Prénom requis !'),
    familyName: Yup.string().required('Nom requis !'),
    source: Yup.string().when('isExternalSource', {
      is: true,
      then: Yup.string().required('Source requise !'),
      otherwise: Yup.string().notRequired(),
    }),
    ...rating,
    ...emailReview,
    ...telephoneReview,
  });
