import { Message } from 'semantic-ui-react';
import React, { FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import TimelineEvent from '../timeline-event';
import { getBilikUserName } from 'utils/get-bilik-username';
import TimelineSolicitationActions from '../timeline-solicitation-actions';

type SollicitationResentProps = {
  event: MongoEvent;
};

const SollicitationResent: FunctionComponent<SollicitationResentProps> = ({
  event,
}) => {
  if (event.version === 1) {
    return (
      <TimelineEvent
        date={event.date}
        icon="mail"
        title="Demande mail renvoyée"
        initiator="bilik-user"
        bilikUserName={getBilikUserName(event.initiator.bilik)}
      >
        <h3>
          Demande mail renvoyée à{' '}
          <span>
            {event.actors.pro.proPresentation.name} (
            {event.actors.pro.proPresentation.mainTrade?.name} -{' '}
            {event.actors.pro.proView.bilikZone.mainCityCode})
          </span>
        </h3>
        <section>
          <TimelineSolicitationActions
            solicitation={event.data.sollicitation}
          />
        </section>
      </TimelineEvent>
    );
  }

  return (
    <Message
      error
      content={`Pas d'event handler trouvé pour SollicitationResent (version ${event.version})`}
    />
  );
};

export default SollicitationResent;
