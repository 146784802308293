import React, { FunctionComponent, useMemo } from 'react';
import { endOfYear, startOfYear, subYears } from 'date-fns';
import { useSolicitationGraphQuery } from 'generated/graphql';
import SolicitationsGraphContainer from './solicitations-graph-container';

const now = new Date();

type SolicitationsGraphRepositoryProps = {
  bilikZoneIds?: number[];
  tradeId?: number;
  year?: number;
  compareYear?: number;
};

const SolicitationsGraphRepository: FunctionComponent<
  SolicitationsGraphRepositoryProps
> = ({
  bilikZoneIds,
  tradeId,
  year = now.getFullYear(),
  compareYear = subYears(now, 1).getFullYear(),
}) => {
  const { date, compareDate } = useMemo(() => {
    const date = new Date(now);
    date.setFullYear(year);

    const compareDate = new Date(now);
    compareDate.setFullYear(compareYear);

    return {
      date: date,
      compareDate: compareDate,
    };
  }, [year, compareYear]);

  const { loading, data } = useSolicitationGraphQuery({
    variables: {
      where: {
        _or: [
          {
            dateCreated: {
              _gte: startOfYear(date).toISOString(),
              _lte: endOfYear(date).toISOString(),
            },
          },
          {
            dateCreated: {
              _gte: startOfYear(compareDate).toISOString(),
              _lte: endOfYear(compareDate).toISOString(),
            },
          },
        ],
        proView: {
          bilikZoneId: bilikZoneIds
            ? {
                _in: bilikZoneIds,
              }
            : undefined,
          trades: {
            tradeId: tradeId ? { _eq: tradeId } : undefined,
          },
        },
      },
    },
  });

  return (
    <SolicitationsGraphContainer
      loading={loading}
      solicitations={data?.solicitation}
      date={date}
      compareDate={compareDate}
    />
  );
};

export default SolicitationsGraphRepository;
