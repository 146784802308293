import {
  OrderBy,
  ProReviewBoolExp,
  useProReviewsTableQueryLazyQuery,
} from 'generated/graphql';
import { useCurrentBilikZones } from 'context/current-bilik-zones-context';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { ProReviewsTableContainer } from './pro-reviews-table-container';

type Props = {
  page?: number;
  where?: ProReviewBoolExp;
  isInProViewForm: boolean;
  orderBy: {
    column: string;
    direction: string;
  };
  offset?: number;
  onSort: (column: string, direction: string) => void;
  onFilter: (column: string, value: string) => void;
  limit: number;
};

export const ProReviewTableRepository: FunctionComponent<Props> = ({
  orderBy,
  offset,
  page,
  where,
  isInProViewForm,
  limit,
  onSort,
  onFilter,
}) => {
  const direction = useMemo(() => {
    switch (orderBy.direction) {
      case 'ascending': {
        return OrderBy.Asc;
      }
      case 'descending': {
        return OrderBy.DescNullsLast;
      }
    }
  }, [orderBy.direction]);

  const [proReviewsTableQuery, { data }] = useProReviewsTableQueryLazyQuery();

  const fetchProReviewsTable = useCallback(async () => {
    return proReviewsTableQuery({
      variables: {
        limit,
        offset,
        order_by: [
          {
            [orderBy.column]: direction,
          },
        ],
        where,
      },
    });
  }, [where, direction, offset]);

  const { currentBilikZones } = useCurrentBilikZones();

  return (
    <ProReviewsTableContainer
      proReviews={data?.proReview}
      refetch={fetchProReviewsTable}
      page={page}
      orderBy={orderBy}
      isInProViewForm={isInProViewForm}
      onSort={onSort}
      onFilter={onFilter}
      currentBilikZones={currentBilikZones}
    />
  );
};
