import { Dimmer, Label, Loader, Table, Image, Popup } from 'semantic-ui-react';
import React, { Fragment, FunctionComponent, useMemo } from 'react';
import { TradesInfoTableQuery } from 'generated/graphql';
import env from 'env';
import { CurrentBilikZones } from 'context/current-bilik-zones-context';

type Props = {
  loading: boolean;
  trades: TradesInfoTableQuery['trade'];
  currentBilikZones: CurrentBilikZones;
  onSort?: (column: string, direction: string) => void;
  orderBy?: {
    column: string;
    direction: string;
  };
};

type ProView = {
  id: number;
  avatarHash?: string;
  proPresentationName?: string;
};

const ProImages: FunctionComponent<{
  proViews: ProView[];
}> = ({ proViews }) => {
  return (
    <div>
      {proViews.map((proView) => {
        if (proView) {
          return (
            <Popup
              position="top center"
              key={proView.id}
              content={proView.proPresentationName}
              trigger={
                <Image
                  key={proView.avatarHash}
                  src={`${env.API_URL}/images/${proView?.avatarHash}/full/0,0/0/default.png`}
                  size="mini"
                  circular
                  inline
                  spaced
                  onClick={(): void => {
                    window.open(`/pros/update/${proView.id}`);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              }
            />
          );
        }
      })}
    </div>
  );
};

export const TradesInfoTableView: FunctionComponent<Props> = ({
  loading,
  trades,
  currentBilikZones,
  orderBy,
  onSort,
}) => {
  const direction = useMemo(() => {
    switch (orderBy?.direction) {
      case 'ascending':
      case 'descending':
        return orderBy.direction;
      default:
        return 'ascending';
    }
  }, [orderBy?.direction]);

  return (
    <Dimmer.Dimmable>
      <Dimmer inverted verticalAlign="top" active={loading}>
        <Loader style={{ marginTop: '20px' }} inline>
          Chargement....
        </Loader>
      </Dimmer>
      <Table columns={6} structured striped celled sortable selectable>
        <Table.Header>
          <Table.HeaderCell
            onClick={(): void => {
              if (onSort) {
                onSort(
                  'name',
                  direction === 'ascending' ? 'descending' : 'ascending',
                );
              }
            }}
            sorted={orderBy?.column === 'name' ? direction : undefined}
          >
            Nom
          </Table.HeaderCell>

          <Table.HeaderCell
            textAlign="center"
            onClick={(): void => {
              if (onSort) {
                onSort(
                  'tradeProViews',
                  direction === 'ascending' ? 'descending' : 'ascending',
                );
              }
            }}
            sorted={orderBy?.column === 'tradeProViews' ? direction : undefined}
          >
            Total
          </Table.HeaderCell>
          <Table.HeaderCell className="not sortable" textAlign="center">
            En catégorie principale
          </Table.HeaderCell>
          <Table.HeaderCell className="not sortable" textAlign="center">
            En catégorie principale bis
          </Table.HeaderCell>
          <Table.HeaderCell className="not sortable" textAlign="center">
            En catégorie secondaire
          </Table.HeaderCell>
          <Table.HeaderCell className="not sortable">Univers</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {trades.map((trade) => {
            const mainTradeProCount = trade.mainTradeProPresentation.reduce(
              (acc, proPresentation) =>
                (acc +=
                  proPresentation.proViewsAggregate.aggregate?.count || 0),
              0,
            );

            const secondaryTradeProCount =
              trade.secondaryTradeProPresentation.reduce(
                (acc, proPresentation) =>
                  (acc +=
                    proPresentation.proViewsAggregate.aggregate?.count || 0),
                0,
              );

            const total = trade.tradeProViewsAggregate.aggregate?.count || 0;

            const otherTradeProCount =
              total - mainTradeProCount - secondaryTradeProCount;

            const mainTradeProViews = trade.mainTradeProPresentation.map(
              (proPresentation) => {
                return {
                  ...proPresentation.proViews[0],
                  proPresentationName: proPresentation.name,
                };
              },
            );
            const secondaryTradeProViews =
              trade.secondaryTradeProPresentation.map((proPresentation) => {
                return {
                  ...proPresentation.proViews[0],
                  proPresentationName: proPresentation.name,
                };
              });
            const otherTradeProViews = trade.tradeProViews
              .filter(
                ({ proView }) =>
                  !mainTradeProViews.find(
                    (mainTradeProView) => mainTradeProView?.id === proView?.id,
                  ) &&
                  !secondaryTradeProViews.find(
                    (secondaryTradeProView) =>
                      secondaryTradeProView?.id === proView?.id,
                  ),
              )
              .map(({ proView }) => {
                return {
                  avatarHash: proView.avatarHash,
                  id: proView.id,
                  proPresentationName: proView.proPresentation.name,
                };
              });

            return (
              <Fragment key={trade.id}>
                <Table.Row>
                  <Table.Cell>{trade.name}</Table.Cell>

                  <Table.Cell textAlign="center">
                    {total || <Label>Aucun</Label>}
                  </Table.Cell>
                  <Table.Cell>
                    {mainTradeProCount > 0 && currentBilikZones ? (
                      <ProImages proViews={mainTradeProViews} />
                    ) : (
                      mainTradeProCount ||
                      (!total && `-`) || <Label>Aucun</Label>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {secondaryTradeProCount > 0 && currentBilikZones ? (
                      <ProImages proViews={secondaryTradeProViews} />
                    ) : (
                      secondaryTradeProCount ||
                      (!total && `-`) || <Label>Aucun</Label>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {otherTradeProCount > 0 && currentBilikZones ? (
                      <ProImages proViews={otherTradeProViews} />
                    ) : (
                      otherTradeProCount ||
                      (!total && `-`) || <Label>Aucun</Label>
                    )}
                  </Table.Cell>

                  <Table.Cell>
                    {(trade.tradeGroups || []).map(({ tradeGroup }) => (
                      <Fragment key={tradeGroup.id}>
                        {tradeGroup.name}
                        {tradeGroup.id !==
                          trade.tradeGroups[trade.tradeGroups.length - 1]
                            .tradeGroup.id && ' | '}
                      </Fragment>
                    ))}
                  </Table.Cell>
                </Table.Row>
              </Fragment>
            );
          })}
        </Table.Body>
      </Table>
    </Dimmer.Dimmable>
  );
};
