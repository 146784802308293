import {
  BooleanParam,
  NumberParam,
  ObjectParam,
  StringParam,
  useQueryParams,
} from 'use-query-params';
import {
  Button,
  Container,
  Icon,
  Input,
  Label,
  Loader,
  Pagination,
} from 'semantic-ui-react';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { useCurrentBilikZones } from 'context/current-bilik-zones-context';
import Content from 'components/content/content';
import ContentHeader from 'components/content-header/content-header';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentBody from 'components/content-body/content-body';
import { useSmsReceivedCount } from 'hooks/use-sms-received-count/use-sms-received-count';
import { SmsReceivedTable } from 'components/sms-received-table';
import styled from 'styled-components';
import { formatE164Telephone } from 'utils/telephone';

const InputStyled = styled(Input)`
  input {
    border-radius: 3px !important;
    border-color: #767676 !important;
    padding: 5px !important;

    &:focus {
      border-color: #3c8dbc !important;
    }
  }
`;

type SmsReceivedListProps = {
  givenProViewId?: number;
  noMargin?: boolean;
  title?: React.ReactElement;
};

const SmsReceivedList: FunctionComponent<SmsReceivedListProps> = ({
  givenProViewId,
  noMargin,
  title,
}) => {
  const [piTelephoneInput, setPiTelephoneInput] = useState<string>('');
  const itemPerPage = useMemo(() => 10, []);

  const [filters, setFilters] = useQueryParams({
    page: NumberParam,
    proViewId: NumberParam,
    piTelephone: StringParam,
    orderBy: ObjectParam,
    // it acts as an overide to the default bilik zone selector.
    isAllZones: BooleanParam,
    isSpam: BooleanParam,
  });

  const { currentBilikZones, loading: currentBilikZonesLoading } =
    useCurrentBilikZones();

  const currentBilikZoneIds = useMemo(
    () => currentBilikZones?.map((zone) => zone.id),
    [currentBilikZones],
  );

  const whereAll = useMemo(() => {
    return {
      proViewId: givenProViewId ?? filters.proViewId ?? undefined,
      piTelephone: filters.piTelephone ?? undefined,
      bilikZoneIds:
        !givenProViewId && !filters.piTelephone
          ? currentBilikZoneIds
          : undefined,
    };
  }, [
    givenProViewId,
    filters.isAllZones,
    currentBilikZoneIds,
    filters.piTelephone,
    filters.proViewId,
  ]);

  const whereSpam = useMemo(() => {
    return {
      ...whereAll,
      isSpam: true,
    };
  }, [whereAll]);

  const whereTable = useMemo(() => {
    return {
      ...whereAll,
      isSpam: filters.isSpam ?? undefined,
    };
  }, [whereAll, filters.isSpam]);

  const { count: count, loading: loadingAll } = useSmsReceivedCount(whereAll);

  const { count: spamCount, loading: loadingSpam } =
    useSmsReceivedCount(whereSpam);

  const totalPages: number = useMemo(() => {
    return Math.ceil((count ? count : 0) / itemPerPage);
  }, [count]);

  const isInProViewForm: boolean = useMemo(
    () => Boolean(givenProViewId),
    [givenProViewId],
  );

  if (!isInProViewForm && currentBilikZonesLoading) {
    return (
      <Content>
        <Loader
          style={{ marginTop: '50px' }}
          size="large"
          active
          inline="centered"
        >
          Chargement...
        </Loader>
      </Content>
    );
  }

  return (
    <Content noMargin={noMargin}>
      <ContentHeader>
        <ContentHeaderTitle>{title}</ContentHeaderTitle>
      </ContentHeader>
      <ContentBody>
        <Button.Group>
          <Button
            loading={loadingAll}
            basic={filters.isSpam === true}
            color="grey"
            type="button"
            onClick={(): void => {
              setFilters({ isSpam: undefined });
            }}
          >
            Total ({count ?? 0})
          </Button>
          <Button
            loading={loadingSpam}
            basic={filters.isSpam === undefined}
            color="red"
            type="button"
            onClick={(): void => {
              setFilters({ isSpam: true });
            }}
          >
            Spam ({spamCount ?? 0})
          </Button>
        </Button.Group>

        {filters.piTelephone ? (
          <Button
            as="div"
            style={{ marginLeft: '12px', marginRight: '0' }}
            labelPosition="right"
          >
            <Button
              color="blue"
              onClick={(): void => {
                setFilters({ piTelephone: undefined });
                setPiTelephoneInput('');
              }}
            >
              <Icon name="delete" />
            </Button>
            <Label basic pointing="left">
              {filters.piTelephone}
            </Label>
          </Button>
        ) : (
          <InputStyled
            style={{
              display: 'inline-block',
              marginLeft: '12px',
            }}
            input={{
              icon: 'search',
              iconPosition: 'left',
            }}
            value={piTelephoneInput}
            onKeyPress={async (event): Promise<void> => {
              if (event.key === 'Enter') {
                const value = event.target.value.replace(/\s/g, '');
                if (value.charAt(0) === '0' && value.length === 10) {
                  setFilters({
                    piTelephone: formatE164Telephone(value),
                    page: undefined,
                  });
                } else if (value.charAt(0) === '+' && value.length === 12) {
                  setFilters({
                    piTelephone: value,
                    page: undefined,
                  });
                } else if (value.startsWith('33') && value.length === 11) {
                  setFilters({
                    piTelephone: `+${value}`,
                    page: undefined,
                  });
                }
              }
            }}
            onChange={(event, { value }): void => setPiTelephoneInput(value)}
            icon={
              filters.piTelephone ? (
                <Icon
                  link
                  name="remove"
                  onClick={(): void => {
                    setFilters({
                      piTelephone: undefined,
                      page: undefined,
                    });
                    setPiTelephoneInput('');
                  }}
                />
              ) : (
                <Icon
                  link
                  name="search"
                  onClick={(): void => {
                    setFilters({
                      piTelephone: piTelephoneInput,
                      page: undefined,
                    });
                  }}
                />
              )
            }
            placeholder="Téléphone du Particulier..."
          />
        )}
        {filters.isAllZones ? (
          <Button
            as="div"
            style={{ marginLeft: '12px', marginRight: '0' }}
            labelPosition="right"
          >
            <Button
              color="blue"
              onClick={(): void => {
                setFilters({ isAllZones: undefined });
              }}
            >
              <Icon name="delete" />
            </Button>
            <Label basic pointing="left">
              Toutes les zones
            </Label>
          </Button>
        ) : null}
        <SmsReceivedTable
          filters={{
            ...whereTable,
            page: filters?.page ?? undefined,
          }}
          onFilter={(column, value): void => {
            setFilters({
              [column]: value,
              page: 1,
            });
          }}
          orderBy={{
            column: filters.orderBy?.column ?? 'dateCreated',
            direction: filters.orderBy?.direction ?? 'descending',
          }}
          onSort={(column, direction): void => {
            setFilters({
              orderBy: { column, direction },
              page: 1,
            });
          }}
          itemPerPage={itemPerPage}
        />

        {totalPages > 1 ? (
          <Container fluid textAlign="right" style={{ marginTop: '15px' }}>
            <Pagination
              activePage={filters.page ?? 1}
              defaultActivePage={1}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={totalPages}
              onPageChange={(_, data): void => {
                setFilters({ page: Number(data.activePage) });
              }}
            />
          </Container>
        ) : null}
      </ContentBody>
    </Content>
  );
};

export default SmsReceivedList;
