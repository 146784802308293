import { useCurrentBilikZones } from 'context/current-bilik-zones-context';
import { SelectCurrentBilikZoneQuery } from 'generated/graphql';
import React, { FunctionComponent, useMemo } from 'react';
import {
  Dropdown,
  DropdownItemProps,
  Label as LabelUi,
} from 'semantic-ui-react';

type SelectCurrentBilikZoneContainerProps = {
  loading: boolean;
  bilikZones: SelectCurrentBilikZoneQuery['bilikZone'];
  onChange?: (zoneId: number[]) => void;
  value?: number;
  allAuthorizedZones?: boolean;
  disabled?: boolean;
};

export const SelectCurrentBilikZoneContainer: FunctionComponent<
  SelectCurrentBilikZoneContainerProps
> = (props) => {
  const {
    setCurrentBilikZones,
    removeCurrentBilikZone,
    currentBilikZones,
    loading,
  } = useCurrentBilikZones();

  const { bilikZones, onChange, allAuthorizedZones, disabled } = props;

  const options: DropdownItemProps[] = useMemo(() => {
    const newOptions: DropdownItemProps[] = [];

    bilikZones.map((bilikZone) =>
      newOptions.push({
        value: bilikZone.id,
        key: bilikZone.id,
        text: bilikZone.name,
      }),
    );

    return newOptions;
  }, [bilikZones, allAuthorizedZones]);

  return (
    <Dropdown
      button
      selection
      loading={loading}
      disabled={loading || disabled}
      multiple
      style={{
        height: '100%',
      }}
      onChange={(_, { value }): void => {
        const selectedZones = value as number[];

        if (onChange) {
          onChange(selectedZones);
        }
        setCurrentBilikZones(selectedZones);
      }}
      options={options || []}
      renderLabel={(item: DropdownItemProps) => {
        return (
          <LabelUi
            basic
            content={item.text}
            removeIcon={'delete'}
            onRemove={(_, data) => {
              if (onChange) {
                onChange([data.value as number]);
              }
              removeCurrentBilikZone(data.value as number);
            }}
          />
        );
      }}
      value={
        currentBilikZones === null
          ? []
          : currentBilikZones?.map((zone) => zone.id)
      }
      placeholder={
        loading ? 'Chargement des zones...' : 'Sélectionner des zones...'
      }
    />
  );
};
