import { useCurrentBilikZones } from 'context/current-bilik-zones-context';
import {
  OrderBy,
  useTradesTableQuery,
  useTradesTableQueryLazyQuery,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';

import { TradesTableContainer } from './trades-table-container';

type Props = {
  filters?: {
    tradeId?: number;
    tradeGroupId?: number;
  };
  orderBy: {
    column: string;
    direction: string;
  };
  onSort?: (column: string, direction: string) => void;
};

export const TradesTableRepository: FunctionComponent<Props> = ({
  filters,
  orderBy,
  onSort,
}) => {
  const { currentBilikZones } = useCurrentBilikZones();

  const getVariablesByBilikZone = (bilikZoneId: number | undefined) => ({
    order_by: [{ name: OrderBy.Asc }],
    where: {
      id: {
        _eq: filters?.tradeId,
      },
      tradeGroups: {
        tradeGroupId: {
          _eq: filters?.tradeGroupId,
        },
      },
    },
    whereProView: {
      bilikZoneId: {
        _eq: bilikZoneId,
      },
      status: {
        _eq: 'published',
      },
    },
    whereProPresentation: {
      proViews: {
        bilikZoneId: {
          _eq: bilikZoneId,
        },
        status: {
          _eq: 'published',
        },
      },
    },
    whereTradeProView: {
      proView: {
        bilikZoneId: {
          _eq: bilikZoneId,
        },
        status: {
          _eq: 'published',
        },
      },
    },
  });

  const [tradeTableQuery, { data: data, loading }] =
    useTradesTableQueryLazyQuery();

  const [tradeTableBisQuery, { data: dataBis }] =
    useTradesTableQueryLazyQuery();

  const tradeBilikZoneId = localStorage.getItem('tradeBilikZoneId');

  const tradeBilikZoneIdBis = localStorage.getItem('tradeBilikZoneIdBis');

  const fetchTradeTableQuery = useCallback(async () => {
    if (tradeBilikZoneId) {
      await tradeTableQuery({
        variables: getVariablesByBilikZone(Number(tradeBilikZoneId)),
      });
    }
  }, [tradeBilikZoneId]);

  const fetchTradeTableBisQuery = useCallback(async () => {
    if (tradeBilikZoneIdBis) {
      await tradeTableBisQuery({
        variables: getVariablesByBilikZone(Number(tradeBilikZoneIdBis)),
      });
    }
  }, [tradeBilikZoneIdBis]);

  return (
    <TradesTableContainer
      loading={loading}
      orderBy={orderBy}
      onSort={onSort}
      trades={data?.trade || []}
      tradesBis={dataBis?.trade || []}
      refetch={fetchTradeTableBisQuery}
      refetchBis={fetchTradeTableQuery}
    />
  );
};
