import React, { FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import TimelineEvent from '../timeline-event';
import { Icon, Label, Message } from 'semantic-ui-react';
import { getBilikUserName } from 'utils/get-bilik-username';
import env from 'env';
import TimelineSolicitationActions from '../timeline-solicitation-actions';

const getActionText = (isAutorespondActive: boolean): string => {
  if (isAutorespondActive) {
    return 'activé';
  }
  return 'désactivé';
};

type SolicitationToggleAutorespondProps = {
  event: MongoEvent;
};

const SolicitationToggleAutorespondV1: FunctionComponent<
  SolicitationToggleAutorespondProps
> = ({ event }) => {
  const isAutorespondActive = event.data.solicitation.isAutorespondActive;

  return (
    <TimelineEvent
      date={event.date}
      icon="mail"
      color={isAutorespondActive ? 'blue' : 'red'}
      title={isAutorespondActive ? 'Suivi activé' : 'Suivi désactivé'}
      initiator={event.initiator.bilik ? 'bilik-user' : 'particulier'}
    >
      <h3>
        Relance demande mail{' '}
        <strong>{getActionText(isAutorespondActive)}</strong> par{' '}
        {event.initiator.bilik ? (
          <span>
            {event.initiator.bilik.givenName +
              ' ' +
              event.initiator.bilik.familyName}
          </span>
        ) : (
          <span>le particulier</span>
        )}{' '}
        sur <span>{event.actors.pro.proPresentation.name}</span>
      </h3>
      <section>
        <TimelineSolicitationActions solicitation={event.data.solicitation} />
      </section>
    </TimelineEvent>
  );
};

const SolicitationToggleAutorespondV2: FunctionComponent<
  SolicitationToggleAutorespondProps
> = ({ event }) => {
  const isAutorespondActive = event.data.solicitation.isAutorespondActive;

  return (
    <TimelineEvent
      date={event.date}
      color={isAutorespondActive ? 'blue' : 'red'}
      icon="mail"
      title={isAutorespondActive ? 'Suivi activé' : 'Suivi désactivé'}
      initiator={event.initiator.bilik ? 'bilik-user' : 'particulier'}
      bilikUserName={
        event.initiator.bilik
          ? getBilikUserName(event.initiator.bilik)
          : undefined
      }
    >
      <h3>
        Relance demande mail{' '}
        <strong>{getActionText(isAutorespondActive)}</strong> par{' '}
        {event.initiator.bilik ? (
          <span>
            {event.initiator.bilik.givenName +
              ' ' +
              event.initiator.bilik.familyName}
          </span>
        ) : (
          <span>le particulier</span>
        )}{' '}
        sur{' '}
        <span>
          {event.actors.pro.proPresentation.name} (
          {event.actors.pro.proView.bilikZone.mainCityCode} -{' '}
          {event.actors.pro.proPresentation.mainTrade?.name})
        </span>
      </h3>
      <section>
        <TimelineSolicitationActions solicitation={event.data.solicitation} />
      </section>
    </TimelineEvent>
  );
};

const SolicitationToggleAutorespond: FunctionComponent<
  SolicitationToggleAutorespondProps
> = ({ event }) => {
  if (event.version === 1) {
    return <SolicitationToggleAutorespondV1 event={event} />;
  } else if (event.version === 2) {
    return <SolicitationToggleAutorespondV2 event={event} />;
  }
  return (
    <Message
      error
      content={`Pas d'event handler trouvé pour SolicitationToggleAutorespond (version ${event.version})`}
    />
  );
};

export default SolicitationToggleAutorespond;
