import env from 'env';
import { useFormikContext } from 'formik';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { ToastError } from 'utils';
import { ProFormUpdateFields } from '../pro-form-update-fields-type';
import { Link } from 'react-router-dom';
import { ProFormCreateModal } from 'components/pro-form-create-modal';
import { useNavigate } from 'react-router';
import { equals } from 'remeda';
import { apiClient } from 'axios-client';
import { getFormErrorMessage } from 'utils/form-error-message';
import { flattenObject } from 'utils/flatten-object';
import { ProFormArchiveModal } from 'components/pro-form-archive-modal';

type ProActionsDropdownProps = {
  proPresentationId: number;
  proViewId: number;
  proOrganizationId?: number;
};

const ProActionsDropdown: FunctionComponent<ProActionsDropdownProps> = ({
  proPresentationId,
  proViewId,
  proOrganizationId,
}) => {
  const { values, setFieldValue, validateForm, handleSubmit } =
    useFormikContext<ProFormUpdateFields>();

  const [proFormCreateModalOpen, setProFormCreateModalOpen] = useState(false);
  const [proFormArchiveModalOpen, setProFormArchiveModalOpen] = useState(false);

  const history = useNavigate();

  const onDraft = useCallback(async (): Promise<void> => {
    const errors = await validateForm();
    const errorValues = Object.values(flattenObject(errors)) as string[];
    const errorMsg = getFormErrorMessage(errorValues);

    if (equals(errors, {})) {
      setFieldValue('status', 'draft');
      setTimeout(() => handleSubmit());
    } else {
      ToastError('Impossible de passer la fiche en brouillon !', errorMsg);
    }
  }, [handleSubmit, setFieldValue]);

  const onOpenWebProView = useCallback(() => {
    window.open(
      `${env.SITE_URL}/presentations/${proViewId}-${values.proPresentation.slug}`,
      '_blank',
    );
  }, [proViewId, values.proPresentation.slug]);

  const onProReviewInvitationPdf = useCallback((): void => {
    window.open(
      `${env.API_URL}/form/review-invitation/generate-paper-review-invitation/${proPresentationId}`,
    );
  }, [proViewId]);

  const onProReviewsExportPdf = useCallback(async () => {
    try {
      const response = await apiClient.get(
        `${env.API_URL}/form/review-pro/export/${proPresentationId}`,
        {
          responseType: 'blob',
        },
      );

      const blob = new Blob([response.data], {
        type: response.data.type,
      });

      window.open(window.URL.createObjectURL(blob));
    } catch (error) {
      ToastError('Erreur', "Impossible de télécharger l'attestation d'avis");
    }
  }, [proPresentationId]);

  return (
    <>
      <Dropdown text="Actions" labeled button floating className="icon">
        <Dropdown.Menu>
          {values.status === 'published' ? (
            <Dropdown.Item
              icon="firstdraft"
              text="Passer en brouillon"
              onClick={onDraft}
            />
          ) : null}
          <Dropdown.Item
            icon="eye"
            text="Voir la fiche"
            onClick={onOpenWebProView}
          />
          <Dropdown.Item
            icon="file pdf"
            text="Formulaire avis papier"
            onClick={onProReviewInvitationPdf}
          />
          <Dropdown.Item
            icon="file pdf"
            text="Attestation d'avis client"
            onClick={onProReviewsExportPdf}
          />
          <Dropdown.Item
            as={Link}
            to={`/pro_review_invitations/create/${proPresentationId}/${proViewId}`}
            icon="plus"
            text="Nouvelle demande d'avis"
          />
          <Dropdown.Item
            as={Link}
            to={`/pro_reviews/create/${proPresentationId}`}
            icon="plus"
            text="Nouvel avis"
          />
          <Dropdown.Item
            icon="plus"
            text="Nouvelle zone"
            onClick={(): void => {
              setProFormCreateModalOpen(true);
            }}
          />
          <Dropdown.Item
            icon="archive"
            text="Archiver"
            onClick={(): void => {
              setProFormArchiveModalOpen(true);
            }}
          />
        </Dropdown.Menu>
      </Dropdown>
      <ProFormArchiveModal
        open={proFormArchiveModalOpen}
        onClose={(): void => {
          setProFormArchiveModalOpen(false);
        }}
        proViewId={proViewId}
        proOrganizationId={proOrganizationId}
      />
      <ProFormCreateModal
        open={proFormCreateModalOpen}
        onClose={(): void => {
          setProFormCreateModalOpen(false);
        }}
        proPresentationId={proPresentationId}
        onProCreated={(proViewId): void => {
          history(`/pros/update/${proViewId}`);
        }}
      />
    </>
  );
};

export default ProActionsDropdown;
