import { Icon, Search, SearchResultsProps } from 'semantic-ui-react';
import React, { FunctionComponent, useCallback } from 'react';
import styled from 'styled-components';

const SearchStyled = styled(Search)`
  width: 100%;

  .input {
    width: 100%;
  }

  input {
    margin-left: 5px !important;
    position: relative !important;
  }

  &:focus {
    border-color: #3c8dbc !important;
  }
`;

type Props = {
  value?: string;
  loading: boolean;
  onBlur: () => void;
  onClickCross: () => void;
  onResultSelect: (value) => void;
  onSearchChange: (data?: string) => void;
  open: boolean;
  results?: SearchResultsProps;
};

const OrganizationSearchFieldView: FunctionComponent<Props> = ({
  value,
  loading,
  onBlur,
  onClickCross,
  onResultSelect,
  onSearchChange,
  open,
  results,
}) => {
  const resultRenderer = useCallback(
    (result) => (
      <p>
        <b>{result.name}</b> - {result.activity ? `${result.activity} - ` : ''}
        <i>{result.siren}</i>
        <br />
        {result.street1} {result.postalCode} {result.city}
      </p>
    ),
    [],
  );

  return (
    <SearchStyled
      open={open}
      input={{
        icon: value ? (
          <Icon name="times" link onClick={onClickCross} />
        ) : (
          'search'
        ),
        iconPosition: 'right',
      }}
      noResultsMessage="Pas de résultats"
      loading={loading}
      placeholder="Chercher une entreprise par nom, SIREN, ou SIRET..."
      onBlur={(): void => onBlur()}
      onResultSelect={(_, data): void => {
        onResultSelect(data.result);
      }}
      resultRenderer={resultRenderer}
      onSearchChange={(_, data): void => {
        onSearchChange(data.value);
      }}
      results={results}
      value={value}
      scroll
    />
  );
};

export default OrganizationSearchFieldView;
