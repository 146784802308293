import Client from 'client';
import gql from 'graphql-tag';
import { ToastError } from 'utils';
import * as Yup from 'yup';

const isExistingName = async (name?: string | null): Promise<boolean> => {
  return Client.query({
    query: gql`
      query ($name: String!) {
        proOrganization(
          where: {
            name: { _eq: $name }
            zohoConsumersAggregate: { count: { predicate: { _gt: 0 } } }
          }
        ) {
          id
          name
        }
      }
    `,
    variables: { name: name || '' },
  }).then(
    (response) =>
      response.data.proOrganization && response.data.proOrganization.length > 0,
  );
};

export default (currentName: string): Yup.ObjectSchema =>
  Yup.object({
    name: Yup.string()
      .required('Le nom est requis !')
      .test(
        'is-name-uniq',
        `ATTENTION ! Ce nom d'entreprise existe déjà dans notre base, veuillez assigner l'entreprise existante à la fiche ou créez l'entreprise manuellement en changeant le nom`,
        async (name) => {
          if (currentName !== name) {
            return isExistingName(name)
              .then((res) => res === false)
              .catch((error) => {
                ToastError(
                  'Erreur',
                  "Impossible de vérifier l'unicité du nom !",
                );
                throw error;
              });
          }
          return true;
        },
      ),
    street1: Yup.string().required("L'adresse est requise !"),
    street2: Yup.string().nullable().notRequired(),
    postalCode: Yup.string().required('Le code postal est requis !'),
    city: Yup.string().required('La ville est requise !'),
    billingEmail: Yup.string()
      .required('Adresse email de facturation requise !')
      .email('Email invalide !'),
    siret: Yup.string()
      .trim()
      .when('isForeign', {
        is: false,
        then: Yup.string().length(14, 'Un SIRET français fait 14 chiffres !'),
      })
      .required('le numéro SIRET est requis'),
    tvaNumber: Yup.string()
      .trim()
      .when('isForeign', {
        is: false,
        then: Yup.string()
          .length(13, 'le numéro de TVA doit faire 13 caractères !')
          .matches(
            /^FR[0-9]{11}$/,
            'Le numéro de TVA est invalide, FR + 11 chiffres !',
          ),
      })
      .required('le numéro TVA est requis'),
    businessStatus: Yup.string()
      .required("Le statut de l'entreprise est requis !")
      .max(
        45,
        'Le statut est trop long, cela ne rentrera pas dans le contract',
      ),
  });
