import {
  BooleanParam,
  NumberParam,
  ObjectParam,
  StringParam,
  useQueryParams,
} from 'use-query-params';
import {
  Button,
  Container,
  Icon,
  Label,
  Loader,
  Pagination,
} from 'semantic-ui-react';
import React, { FunctionComponent, useMemo } from 'react';
import { PrivateIndividualSearch } from 'components/private-individual-search-filter';
import { ProPresentationSearchFilter } from 'components/pro-presentation-search-filter';
import { SolicitationBoolExp } from 'generated/graphql';
import { SolicitationsTable } from '../solicitations/solicitations-table';
import { useCurrentBilikZones } from 'context/current-bilik-zones-context';
import { useSolicitationsCount } from 'hooks/use-solicitations-count/use-solicitations-count';
import Content from 'components/content/content';
import ContentHeader from 'components/content-header/content-header';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentBody from 'components/content-body/content-body';

type SolicitationsListProps = {
  givenProPresentationId?: number;
  givenBilikZoneId?: number;
  noMargin?: boolean;
  title?: React.ReactElement;
};

const SolicitationsList: FunctionComponent<SolicitationsListProps> = ({
  givenProPresentationId,
  givenBilikZoneId,
  noMargin,
  title,
}) => {
  const itemPerPage = useMemo(() => 10, []);

  const { currentBilikZones } = useCurrentBilikZones();

  const currentBilikZoneIds = useMemo(
    () => currentBilikZones?.map((zone) => zone.id),
    [currentBilikZones],
  );

  const [filters, setFilters] = useQueryParams({
    hasProReview: BooleanParam,
    ip: StringParam,
    page: NumberParam,
    proPresentationId: NumberParam,
    solicitationId: NumberParam,
    privateIndividualEmail: StringParam,
    isAutorespondActive: BooleanParam,
    // it acts as an override to the default bilik zone selector.
    isAllZones: BooleanParam,
    orderBy: ObjectParam,
    bouncedEntity: StringParam,
  });

  const whereAll: SolicitationBoolExp = useMemo(() => {
    const baseProView = {
      proPresentationId: {
        _eq: givenProPresentationId ?? filters?.proPresentationId ?? undefined,
      },
    };

    return {
      id: { _eq: filters?.solicitationId ?? undefined },
      email: {
        _eq: filters?.privateIndividualEmail ?? undefined,
      },
      ip: {
        _eq: filters?.ip ?? undefined,
      },
      bounceEntity: {
        _eq: filters?.bouncedEntity ?? undefined,
      },
      proView: {
        ...baseProView,
        bilikZone:
          filters?.isAllZones || !currentBilikZoneIds
            ? undefined
            : {
                id: {
                  _in: currentBilikZoneIds,
                },
              },
      },
    };
  }, [
    filters?.privateIndividualEmail,
    filters?.ip,
    filters?.proPresentationId,
    filters?.solicitationId,
    filters?.bouncedEntity,
    currentBilikZoneIds,
    filters?.isAllZones,
    givenBilikZoneId,
    givenProPresentationId,
  ]);

  const whereTable: SolicitationBoolExp = useMemo(() => {
    return {
      ...whereAll,
      proReviewId: {
        _isNull:
          filters?.hasProReview === null || filters?.hasProReview === undefined
            ? undefined
            : !filters?.hasProReview,
      },
      isAutorespondActive: {
        _eq: filters?.isAutorespondActive ?? undefined,
      },
    };
  }, [filters?.hasProReview, filters?.isAutorespondActive, whereAll]);

  const whereHasProReview: SolicitationBoolExp = useMemo(() => {
    return {
      ...whereAll,
      proReviewId: {
        _isNull: false,
      },
    };
  }, [whereAll]);

  const whereIsAutorespondActive: SolicitationBoolExp = useMemo(() => {
    return {
      ...whereAll,
      isAutorespondActive: {
        _eq: true,
      },
    };
  }, [whereAll]);

  const whereInAutorespondInactive: SolicitationBoolExp = useMemo(() => {
    return {
      ...whereAll,
      isAutorespondActive: {
        _eq: false,
      },
    };
  }, [whereAll]);

  const { count: countTable } = useSolicitationsCount(whereTable);

  const { count: countAll, loading: loadingAll } =
    useSolicitationsCount(whereAll);

  const { count: countHasProReview, loading: loadingHasProReview } =
    useSolicitationsCount(whereHasProReview);

  const { count: countAutorespondActive, loading: loadingAutorespondActive } =
    useSolicitationsCount(whereIsAutorespondActive);

  const {
    count: countIsAutorespondInactive,
    loading: loadingAutorespondInactive,
  } = useSolicitationsCount(whereInAutorespondInactive);

  const totalPages: number = useMemo(() => {
    return Math.ceil((countTable ? countTable : 0) / itemPerPage);
  }, [countTable]);

  const isInProViewForm: boolean = useMemo(
    () => Boolean(givenProPresentationId && givenBilikZoneId),
    [givenProPresentationId, givenBilikZoneId],
  );

  if (!isInProViewForm && currentBilikZones === undefined) {
    return (
      <Content>
        <Loader
          style={{ marginTop: '50px' }}
          size="large"
          active
          inline="centered"
        >
          Chargement...
        </Loader>
      </Content>
    );
  }

  return (
    <Content
      noMargin={noMargin}
      style={{
        overflowY: 'auto',
      }}
    >
      <ContentHeader>
        <ContentHeaderTitle>{title}</ContentHeaderTitle>
      </ContentHeader>
      <ContentBody>
        <Button.Group>
          <Button
            loading={loadingAll}
            basic={
              filters?.isAutorespondActive === undefined &&
              filters?.hasProReview === undefined
                ? false
                : true
            }
            color="grey"
            type="button"
            onClick={(): void => {
              setFilters({
                hasProReview: undefined,
                isAutorespondActive: undefined,
                page: undefined,
              });
            }}
          >
            Tous ({countAll ?? 0})
          </Button>

          <Button
            loading={loadingHasProReview}
            basic={filters?.hasProReview ? false : true}
            color="green"
            type="button"
            onClick={(): void => {
              setFilters({
                isAutorespondActive: undefined,
                hasProReview: true,
                page: undefined,
              });
            }}
          >
            Avis réalisés ({countHasProReview ?? 0})
          </Button>
          <Button
            loading={loadingAutorespondActive}
            basic={filters?.isAutorespondActive ? false : true}
            color="red"
            type="button"
            onClick={(): void => {
              setFilters({
                isAutorespondActive: true,
                hasProReview: undefined,
                page: undefined,
              });
            }}
          >
            Relances actives ({countAutorespondActive ?? 0})
          </Button>
          <Button
            loading={loadingAutorespondInactive}
            basic={filters?.isAutorespondActive === false ? false : true}
            color="grey"
            type="button"
            onClick={(): void => {
              setFilters({
                isAutorespondActive: false,
                hasProReview: undefined,
                page: undefined,
              });
            }}
          >
            Relances inactives ({countIsAutorespondInactive ?? 0})
          </Button>
        </Button.Group>

        {!isInProViewForm ? (
          <ProPresentationSearchFilter
            proPresentationId={filters?.proPresentationId ?? undefined}
            setProPresentationId={(value): void => {
              setFilters({
                proPresentationId: value,
              });
            }}
            setPage={(value): void => {
              setFilters({
                page: value,
              });
            }}
            bilikZoneIds={currentBilikZoneIds}
            isAutorespondActive={filters?.isAutorespondActive ?? undefined}
          />
        ) : null}

        {filters?.privateIndividualEmail ? (
          <Button
            as="div"
            style={{ marginLeft: '12px', marginRight: '0' }}
            labelPosition="right"
          >
            <Button
              color="blue"
              onClick={(): void => {
                setFilters({
                  privateIndividualEmail: undefined,
                });
              }}
            >
              <Icon name="delete" />
            </Button>
            <Label basic pointing="left">
              {filters?.privateIndividualEmail}
            </Label>
          </Button>
        ) : (
          <PrivateIndividualSearch
            onResultSelect={(event, { result }): void => {
              setFilters({
                privateIndividualEmail: result.value,
                page: undefined,
              });
            }}
            searchContext="solicitation"
            proPresentationId={givenProPresentationId}
          />
        )}
        {filters?.ip ? (
          <Button
            as="div"
            style={{ marginLeft: '12px', marginRight: '0' }}
            labelPosition="right"
          >
            <Button
              color="blue"
              onClick={(): void => {
                setFilters({
                  ip: undefined,
                });
              }}
            >
              <Icon name="delete" />
            </Button>
            <Label basic pointing="left">
              {filters?.ip}
            </Label>
          </Button>
        ) : null}
        {filters?.solicitationId ? (
          <Button
            as="div"
            style={{ marginLeft: '12px', marginRight: '0' }}
            labelPosition="right"
          >
            <Button
              color="blue"
              onClick={(): void => {
                setFilters({
                  solicitationId: undefined,
                });
              }}
            >
              <Icon name="delete" />
            </Button>
            <Label basic pointing="left">
              {`Demande : ${filters?.solicitationId}`}
            </Label>
          </Button>
        ) : null}
        {filters?.isAllZones ? (
          <Button
            as="div"
            style={{ marginLeft: '12px', marginRight: '0' }}
            labelPosition="right"
          >
            <Button
              color="blue"
              onClick={(): void => {
                setFilters({
                  isAllZones: undefined,
                });
              }}
            >
              <Icon name="delete" />
            </Button>
            <Label basic pointing="left">
              Toutes les zones
            </Label>
          </Button>
        ) : null}
        <SolicitationsTable
          where={whereTable}
          page={filters?.page ?? undefined}
          onFilter={(column, value): void => {
            setFilters({
              [column]: value,
              page: 1,
            });
          }}
          orderBy={{
            column: filters.orderBy?.column ?? 'dateCreated',
            direction: filters.orderBy?.direction ?? 'descending',
          }}
          onSort={(column, direction): void => {
            setFilters({
              orderBy: { column, direction },
              page: 1,
            });
          }}
          isInProViewForm={isInProViewForm}
        />
        {totalPages > 1 ? (
          <Container fluid textAlign="right" style={{ marginTop: '15px' }}>
            <Pagination
              activePage={filters?.page ?? 1}
              defaultActivePage={1}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={totalPages}
              onPageChange={(_, data): void => {
                setFilters({
                  page: Number(data.activePage),
                });
              }}
            />
          </Container>
        ) : null}
      </ContentBody>
    </Content>
  );
};

export default SolicitationsList;
