import React, { FunctionComponent, useState } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';
import TimelineEmailPreview from '../timeline-email-preview';
import TimelineEvent from '../timeline-event';
import { Message } from 'semantic-ui-react';
import TimelineSolicitationActions from '../timeline-solicitation-actions';

type AutorespondMailSentProps = {
  event: MongoEvent;
};

const AutorespondMailSentV1: FunctionComponent<AutorespondMailSentProps> = ({
  event,
}) => {
  return (
    <TimelineEvent
      date={event.date}
      icon="mail"
      title={
        event.data.solicitation
          ? 'Mail de suivi demande mail'
          : "Mail de relance demande d'avis"
      }
      initiator="bilik-bot"
    >
      <h3>
        {event.data.solicitation
          ? `Mail de suivi suite à la demande du particulier à `
          : `Mail de relance suite à la demande d'avis de `}
        <span>{event.actors.pro.proPresentation.name}</span>
      </h3>
      <section>
        <TimelineEmailPreview
          eventDate={event.date}
          messageId={event.data.email?.messageId}
        />
        {event.data.solicitation && (
          <TimelineSolicitationActions solicitation={event.data.solicitation} />
        )}
      </section>
    </TimelineEvent>
  );
};

const AutorespondMailSentV2: FunctionComponent<AutorespondMailSentProps> = ({
  event,
}) => {
  return (
    <TimelineEvent
      date={event.date}
      icon="mail"
      title={
        event.data.solicitation
          ? 'Mail de suivi demande mail'
          : "Mail de relance demande d'avis"
      }
      initiator="bilik-bot"
    >
      <h3>
        {event.data.solicitation
          ? `Mail de suivi suite à la demande du particulier à `
          : `Mail de relance suite à la demande d'avis de `}
        <span>
          {event.actors.pro.proPresentation.name} (
          {event.actors.pro.proView.bilikZone.mainCityCode} -{' '}
          {event.actors.pro.proPresentation.mainTrade?.name})
        </span>
      </h3>
      <section>
        <TimelineEmailPreview
          eventDate={event.date}
          messageId={event.data.email?.messageId}
        />
        {event.data.solicitation && (
          <TimelineSolicitationActions solicitation={event.data.solicitation} />
        )}
      </section>
    </TimelineEvent>
  );
};

const AutorespondMailSentV3: FunctionComponent<AutorespondMailSentProps> = ({
  event,
}) => {
  const getAutoresponderSolicitationNumber = (): string => {
    if (event.data.emailTemplate == 'solicitation_two_working_days') {
      return 'Avez vous recu une réponse de ';
    } else if (event.data.emailTemplate == 'solicitation_x_days') {
      return 'Est-ce que tout se passe bien avec ';
    } else if (event.data.emailTemplate == 'solicitation_x_forty_five_days') {
      return 'Où en êtes-vous avec ';
    } else {
      return '';
    }
  };

  const getAutoresponderSolicitationTemplateName = (): string => {
    if (event.data.emailTemplate == 'solicitation_two_working_days') {
      return 'Mail suivi n°1 (J+2)';
    } else if (event.data.emailTemplate == 'solicitation_x_days') {
      return 'Mail suivi n°2';
    } else if (event.data.emailTemplate == 'solicitation_x_forty_five_days') {
      return 'Mail suivi n°3 (J+45)';
    } else {
      return '';
    }
  };

  return (
    <TimelineEvent
      date={event.date}
      icon="mail"
      title={
        event.data.solicitation
          ? getAutoresponderSolicitationTemplateName()
          : "Mail de relance demande d'avis"
      }
      initiator="bilik-bot"
    >
      <h3>
        {event.data.solicitation
          ? `Mail automatique de suivi envoyé au particulier (` +
            getAutoresponderSolicitationNumber()
          : `Mail de relance suite à la demande d'avis de `}
        <span>
          {event.actors.pro.proPresentation.name} (
          {event.data.solicitation
            ? event.actors.pro.proView.bilikZone.mainCityCode + ' '
            : ''}
          {event.actors.pro.proPresentation.mainTrade?.name})
        </span>
        {event.data.solicitation ? ')' : ''}
      </h3>
      <section>
        <TimelineEmailPreview
          eventDate={event.date}
          messageId={event.data.email?.messageId}
        />
        {event.data.solicitation && (
          <TimelineSolicitationActions solicitation={event.data.solicitation} />
        )}
      </section>
    </TimelineEvent>
  );
};

const AutorespondMailSent: FunctionComponent<AutorespondMailSentProps> = ({
  event,
}) => {
  if (event.version === 1) {
    return <AutorespondMailSentV1 event={event} />;
  } else if (event.version === 2) {
    return <AutorespondMailSentV2 event={event} />;
  } else if (event.version === 3) {
    return <AutorespondMailSentV3 event={event} />;
  }

  return (
    <Message
      error
      content={`Pas d'event handler trouvé pour Autorespond (version ${event.version})`}
    />
  );
};

export default AutorespondMailSent;
