import { FastField, useFormikContext } from 'formik';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Form,
  Grid,
  Icon,
  Segment,
  Label as LabelUi,
  Divider,
  Menu,
  Container,
  Modal,
  Button,
  Image,
} from 'semantic-ui-react';
import { useNavigate } from 'react-router';
import { ProFormUpdateFields } from './pro-form-update-fields-type';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';
import { BilikZone } from 'generated/graphql';
import ProPublishButton from './pro-publish-button/pro-publish-button';
import ProActionsDropdown from './pro-actions-dropdown/pro-actions-dropdown';
import ProSubmitButton from './pro-submit-button/pro-submit-button';
import AvatarUploadContainer from 'components/avatar-upload/avatar-upload';
import ProInfosList from './pro-infos-list/pro-infos-list';
import ProZones from './pro-zones/pro-zones';
import ProRouter from './pro-router/pro-router';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import HelpText from 'components/help-text/help-text';
import FormField from 'components/form-field/form-field';
import ContentContainer from 'components/content-container/content-container';
import { useSlugInCircularDependency } from 'hooks/use-slug-in-circular-dependency/use-slug-in-circular-dependency';
import SlugRedirectModal from 'components/slug-redirect-modal/slug-redirect-modal';
import { useDebounce } from 'use-debounce';
import './pro-form-update.css';
import { useIsUserAdmin } from 'hooks/use-is-user-admin/use-is-user-admin';
import { useParams } from 'react-router';
import env from 'env';
import { useCheckSecondaryTradeAssociation } from 'hooks/use-check-secondary-trade-association/use-check-secondary-trade-association';

type ProFormUpdateViewProps = {
  proViewId: number;
  proPresentationId: number;
  mainTradeName?: string;
  bilikZone: Pick<BilikZone, 'id' | 'slug' | 'mainCityCode'>;
  proOrganizationId?: number;
};

const ProFormUpdateView: FunctionComponent<ProFormUpdateViewProps> = ({
  proViewId,
  proPresentationId,
  mainTradeName,
  bilikZone,
  proOrganizationId,
}) => {
  const isUserAdmin = useIsUserAdmin();
  const history = useNavigate();
  const { values, setFieldValue, handleReset, handleSubmit, initialValues } =
    useFormikContext<ProFormUpdateFields>();

  const [hasProOrganizationChanged, setHasProOrganizationChanged] =
    useState<boolean>(false);

  const tradeRecommendations = useCheckSecondaryTradeAssociation(
    values.trades,
    values.proPresentation.mainTradeId,
    values.proPresentation.secondaryTradeId,
  );

  useEffect(() => {
    const _hasProOrganizationChanged =
      values.proPresentation.proOrganizationId !== proOrganizationId;

    setHasProOrganizationChanged(Boolean(_hasProOrganizationChanged));
  }, [values.proPresentation.proOrganizationId, proOrganizationId]);

  // This modal gets triggered before handleSubmit if organization changed
  // it remember admins to disable subscriptions before change the organization.
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isTradeRecommendationsModalOpen, setIsTradeRecommendationsModalOpen] =
    useState(false);

  const handleSubmitWithOrganizationConfirmation = useCallback(async () => {
    if (hasProOrganizationChanged && proOrganizationId) {
      // Confirm modal
      setIsConfirmationModalOpen(true);
    } else {
      if (tradeRecommendations.length) {
        setIsTradeRecommendationsModalOpen(true);
        return;
      }

      return handleSubmit();
    }
  }, [hasProOrganizationChanged, tradeRecommendations]);

  const statusColor = useMemo((): SemanticCOLORS => {
    switch (values.status) {
      case 'published':
        return 'green';
      case 'draft':
        return 'red';
      default:
        return 'grey';
    }
  }, [values.status]);

  const statusText = useMemo((): 'Publié' | 'Brouillon' | 'Archivé' => {
    switch (values.status) {
      case 'published':
        return 'Publié';
      case 'draft':
        return 'Brouillon';
      default:
        return 'Archivé';
    }
  }, [values.status]);

  const params = useParams<{
    path: string;
  }>();

  const isActiveRoute = useCallback(
    (path?: string): boolean => path === params.path,
    [params.path],
  );

  const onRouteChange = useCallback(
    (path: string) => {
      history(`/pros/update/${proViewId}/${path}`);
    },
    [proViewId, history],
  );

  const [debounceSlugValue] = useDebounce(values.proPresentation.slug, 600);

  const circularDependencies = useSlugInCircularDependency(
    debounceSlugValue,
    initialValues.proPresentation.slug,
  );

  const [isNoteChange, setIsNoteChange] = useState<boolean>(false);

  useEffect(() => {
    if (
      values.note === initialValues.note ||
      (!values.note && !initialValues.note)
    ) {
      setIsNoteChange(false);
    } else {
      setIsNoteChange(true);
    }
  }, [values.note]);

  return (
    <>
      <SlugRedirectModal circularDependencies={circularDependencies} />
      <Form onReset={handleReset}>
        <Grid>
          <Grid.Row
            columns={2}
            style={{
              maxWidth: '1528px',
              paddingBottom: '0',
              flexWrap: 'nowrap',
            }}
          >
            <Grid.Column width={12}>
              <ContentHeaderTitle>
                <LabelUi
                  style={{ verticalAlign: 'middle', marginRight: '10px' }}
                  color={statusColor}
                >
                  <Icon name="circle" size="small" />
                  {statusText}
                </LabelUi>
                {values.proPresentation.name} - {mainTradeName} -{' '}
                {bilikZone.mainCityCode}
              </ContentHeaderTitle>
            </Grid.Column>
            <Grid.Column textAlign="right" width={5}>
              <ProActionsDropdown
                proPresentationId={proPresentationId}
                proViewId={proViewId}
                proOrganizationId={proOrganizationId}
              />
              {values.status === 'draft' ? (
                <ProPublishButton proViewId={proViewId} />
              ) : null}
              <ProSubmitButton
                overridedHandleSubmit={handleSubmitWithOrganizationConfirmation}
                disabled={values.status === 'archived'}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4} style={{ maxWidth: '300px' }}>
              <LabelUi color="grey">N°{proViewId} (PV)</LabelUi>
              <LabelUi color="grey">N°{proPresentationId} (PP)</LabelUi>
              <LabelUi color="grey">N°{proOrganizationId} (PO)</LabelUi>
              <Segment color="blue">
                <Container fluid textAlign="center">
                  <HelpText>La photo doit être carré</HelpText>
                </Container>
                <div
                  style={{
                    margin: 'auto',
                    maxWidth: '225px',
                    height: '225px',
                    marginBottom: '20px',
                    position: 'relative',
                  }}
                >
                  <FastField
                    name="avatarHash"
                    component={AvatarUploadContainer}
                    value={values.avatarHash}
                    onChange={(event, { value }): void => {
                      // When removing the avatar reset the originalAvatarHash
                      if (!value) {
                        setFieldValue('originalAvatarHash', null);
                      }

                      // Set the originalAvatarHash on first upload
                      if (!values.originalAvatarHash && !values.avatarHash) {
                        setFieldValue('originalAvatarHash', value);
                      }

                      setFieldValue('avatarHash', value);
                    }}
                    onRestore={
                      values.originalAvatarHash &&
                      values.originalAvatarHash !== values.avatarHash
                        ? () =>
                            setFieldValue(
                              'avatarHash',
                              values.originalAvatarHash,
                            )
                        : undefined
                    }
                    restorePopupContent={
                      <Image
                        src={`${env.API_URL}/images/${values.originalAvatarHash}/full/0,0/0/default.jpg`}
                        alt="Image originale"
                      />
                    }
                  />
                </div>
                <ProInfosList proViewId={proViewId} />
                <ProZones
                  activeRoute={params.path}
                  proViewId={proViewId}
                  proPresentationId={proPresentationId}
                />
                <Divider horizontal>Notes</Divider>
                <FormField
                  type="textarea"
                  name="note"
                  placeholder="Notes internes sur le pro"
                  rows={10}
                  resize="none"
                />
                <span style={{ color: 'red' }}>
                  {isNoteChange ? 'Pensez à sauvegarder la fiche !' : ''}
                </span>
                {isUserAdmin && values.status === 'published' ? (
                  <>
                    <Divider horizontal>Admin</Divider>
                    <FormField
                      type="date"
                      label="Date de publication"
                      name="datePublished"
                      values={values}
                      dateFormat="dd/MM/yyyy"
                      required
                      maxDate={new Date()}
                    />
                  </>
                ) : null}
              </Segment>
            </Grid.Column>
            <Grid.Column width={12}>
              <ContentContainer style={{ marginTop: '-14px' }}>
                <Menu pointing secondary id="pro-menu">
                  <Menu.Item
                    active={isActiveRoute()}
                    onClick={(): void => {
                      onRouteChange('');
                    }}
                    name={bilikZone.mainCityCode}
                  />
                  <Menu.Item
                    active={isActiveRoute('presentation')}
                    onClick={(): void => {
                      onRouteChange('presentation');
                    }}
                    name="Fiche"
                  />
                  <Menu.Item
                    active={isActiveRoute('sectors')}
                    onClick={(): void => {
                      onRouteChange('sectors');
                    }}
                    icon="map marker alternate"
                  />
                  <Menu.Item
                    active={isActiveRoute('organization')}
                    onClick={(): void => {
                      onRouteChange('organization');
                    }}
                    icon="euro sign"
                  />
                  <Menu.Item
                    active={isActiveRoute('gallery')}
                    onClick={(): void => {
                      onRouteChange('gallery');
                    }}
                    icon="images outline"
                  />
                  <Menu.Item
                    active={isActiveRoute('messages')}
                    onClick={(): void => {
                      onRouteChange('messages');
                    }}
                    icon="bell outline"
                  />
                  <Menu.Item
                    active={isActiveRoute('holidays')}
                    onClick={(): void => {
                      onRouteChange('holidays');
                    }}
                    icon="clock outline"
                  />
                  <Menu.Item
                    active={isActiveRoute('review_invitations')}
                    onClick={(): void => {
                      onRouteChange('review_invitations');
                    }}
                    content={
                      <span className="fa-stack fa-2x stacked-icon">
                        <i className="far fa-comment fa-stack-2x"></i>
                        <i className="fas fa-question fa-stack-1x question-icon"></i>
                      </span>
                    }
                  />
                  <Menu.Item
                    active={isActiveRoute('stats')}
                    onClick={(): void => {
                      onRouteChange('stats');
                    }}
                    icon="chart bar"
                  />
                  <Menu.Item
                    active={isActiveRoute('calls')}
                    onClick={(): void => {
                      onRouteChange('calls');
                    }}
                    icon="call"
                  />
                  <Menu.Item
                    active={isActiveRoute('pro_reviews')}
                    onClick={(): void => {
                      onRouteChange('pro_reviews');
                    }}
                    icon="comment outline"
                  />
                  <Menu.Item
                    active={isActiveRoute('solicitations')}
                    onClick={(): void => {
                      onRouteChange('solicitations');
                    }}
                    icon="envelope"
                  />
                  <Menu.Item
                    active={isActiveRoute('sms-received')}
                    onClick={(): void => {
                      onRouteChange('sms-received');
                    }}
                    icon="comment alternate"
                  />
                </Menu>
                <ProRouter
                  proPresentationId={proPresentationId}
                  proViewId={proViewId}
                  bilikZoneId={bilikZone.id}
                  activeRoute={params.path}
                  proOrganizationId={proOrganizationId}
                  hasProOrganizationChanged={hasProOrganizationChanged}
                />
              </ContentContainer>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>

      <Modal open={isConfirmationModalOpen}>
        <Modal.Header>Changement d&apos;entreprise</Modal.Header>
        <Modal.Content>
          <p>
            <b style={{ color: 'red' }}>
              Attention, pour changer l&apos;entreprise de cette fiche, il faut
              :
            </b>
          </p>
          <ul>
            <li>
              <b>
                Vérifier qu&apos;il n&apos;y ait pas d&apos;abonnements actifs
                sur l&apos;ancienne entreprise
              </b>
              . Sinon, l&apos;entreprise continuera d&apos;être facturée.
            </li>
            <li>
              Pensez à régénérer un nouveau contrat avec la nouvelle entreprise
              une fois modifiée.
            </li>
          </ul>

          <p>Confirmez-vous avoir bien vérifié ?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            negative
            icon="remove"
            content="Non"
            onClick={(): void => setIsConfirmationModalOpen(false)}
          />
          <Button
            type="button"
            onClick={(): void => {
              setIsConfirmationModalOpen(false);
              handleSubmit();
            }}
            positive
            icon="checkmark"
            content="Oui"
          />
        </Modal.Actions>
      </Modal>

      <Modal open={isTradeRecommendationsModalOpen}>
        <Modal.Header>
          <Icon name="warning sign" color="red" />
          Attention
        </Modal.Header>
        <Modal.Content>
          {tradeRecommendations ? (
            <ul>
              {tradeRecommendations?.map((recommendation, index) => {
                return (
                  <li key={index}>
                    Pour que le pro apparaisse en{' '}
                    <b>{recommendation.tradeGuideName}</b> sur le guide, il faut
                    lui attribuer <b>{recommendation.secondaryTradeName}</b> en
                    catégorie principales BIS.
                  </li>
                );
              })}
            </ul>
          ) : null}
          Tu peux consulter{' '}
          <a href="/trades_guide" target="_blank" rel="noopener noreferrer">
            les associations de catégories spécifiques au guide{' '}
          </a>{' '}
          (métiers split) pour en savoir plus.
        </Modal.Content>

        <Modal.Actions>
          <Button
            onClick={(): void => {
              setIsTradeRecommendationsModalOpen(false);
            }}
          >
            Annuler
          </Button>
          <Button
            primary
            onClick={(): void => {
              setIsTradeRecommendationsModalOpen(false);
              handleSubmit();
            }}
          >
            Continuer
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ProFormUpdateView;
