import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import FormField, {
  FormFieldSelectProps,
} from 'components/form-field/form-field';
import {
  useSearchCityFieldDefaultQuery,
  useSearchCityFieldLazyQuery,
} from 'generated/graphql';
import { useDebounce } from 'use-debounce';
import { getIn, useFormikContext } from 'formik';

type SearchCityFieldProps = Omit<
  FormFieldSelectProps,
  'options' | 'type' | 'loading' | 'disabled' | 'search' | 'onSearchChange'
>;

const SearchCityField: FunctionComponent<SearchCityFieldProps> = ({
  ...rest
}) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [options, setOptions] = useState<DropdownItemProps[]>([]);
  const [debouncedSearchQuery] = useDebounce(searchQuery, 600);

  const { values } = useFormikContext();

  const defaultCityId: number = useMemo(() => getIn(values, rest.name), []);

  const [citySearchFilterQuery] = useSearchCityFieldLazyQuery();

  const { data } = useSearchCityFieldDefaultQuery({
    variables: {
      id: defaultCityId || 0, // TODO works, but is hacky
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const city = data?.cityByPk;
    if (city) {
      setOptions([
        {
          value: city.id,
          key: city.slug,
          text: `${city.name} (${city.department.code})`,
        },
      ]);
    }
  }, [data]);

  useEffect(() => {
    if (debouncedSearchQuery.length < 2) {
      setOptions([]);
      return;
    }

    citySearchFilterQuery({
      variables: {
        criteria: debouncedSearchQuery
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase(),
      },
      fetchPolicy: 'no-cache',
    }).then(({ data }) => {
      setOptions(
        data?.searchCity.map((city) => ({
          value: city.id,
          key: city.slug,
          text: `${city.name} (${city.department.code})`,
        })) ?? [],
      );
    });
  }, [debouncedSearchQuery]);

  return (
    <FormField
      type="select"
      search
      options={options}
      onSearchChange={(_, { searchQuery }) => setSearchQuery(searchQuery)}
      selectOnBlur={rest.selectOnBlur || false}
      {...rest}
    />
  );
};

export default SearchCityField;
