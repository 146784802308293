import React, { FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import TimelineEvent from '../timeline-event';
import Raw from 'components/raw/raw';
import { formatTelephone } from 'utils/telephone';

type FormCreateNoResultProps = {
  event: MongoEvent;
};

const FormCreateNoResult: FunctionComponent<FormCreateNoResultProps> = ({
  event,
}) => {
  return (
    <TimelineEvent
      date={event.date}
      icon="mail"
      color="red"
      title="Formulaire pas de résultat"
      initiator="particulier"
    >
      <h3>
        Le particulier a contacté{' '}
        <b>
          {event.actors.bilik.givenName
            ? `${event.actors.bilik.givenName} ${event.actors.bilik.familyName}`
            : event.actors.bilik.email}
        </b>{' '}
        après une recherche sans résultat :{' '}
        <span>
          {event.data.noResultForm.searchContextTradeName} à{' '}
          {event.data.noResultForm.searchContextZoneName}
        </span>
      </h3>
      <section>
        <p>
          <b>Prénom Nom : </b> {event.actors.pi.givenName}{' '}
          {event.actors.pi.familyName}
        </p>
        <p>
          <b>Email : </b> {event.actors.pi.email}
        </p>
        <p>
          <b>Téléphone : </b> {formatTelephone(event.actors.pi.telephone)}
        </p>
      </section>
      <section>
        <b>Description du projet : </b>
        <br />
        <br />
        <Raw>{event.data.noResultForm.message}</Raw>
        <br />
        <br />
        <p>
          <b>Lieu de la prestation : </b>{' '}
          {event.data.noResultForm.addressLocality}
        </p>
        {event.data.noResultForm.delay && (
          <>
            <p>
              <b>Délai : </b>
              {event.data.noResultForm.delay}
            </p>
          </>
        )}
      </section>
    </TimelineEvent>
  );
};

export default FormCreateNoResult;
