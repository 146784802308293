import { AxiosResponse } from 'axios';
import { Formik } from 'formik';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { ToastError, ToastSuccess } from 'utils';
import { ProMandateFormFields } from './pro-mandate-form-fields-type';
import { ProMandateFormProps } from './pro-mandate-form-modal';
import mandateFormValidationSchema from './pro-mandate-form-validation-schema';
import ProMandateFormView from './pro-mandate-form-view';

type ProMandateFormCreateContainerProps = ProMandateFormProps & {
  createProMandate: (proMandate: {
    bic: string;
    iban: string;
    zohoOrganizationId: number;
    proOrganizationId: number;
  }) => Promise<AxiosResponse>;
  currentBilikPersonId: number;
};

const ProMandateFormCreateContainer: FunctionComponent<
  ProMandateFormCreateContainerProps
> = ({
  createProMandate,
  onClose,
  onProMandateCreated,
  proOrganizationId,
  currentBilikPersonId,
  ...rest
}) => {
  const onSubmit = useCallback(
    async (values: ProMandateFormFields) => {
      await createProMandate({
        bic: values.bic.toUpperCase().replace(/ /g, ''),
        iban: values.iban.toUpperCase().replace(/ /g, ''),
        zohoOrganizationId: values.zohoOrganizationId,
        proOrganizationId: proOrganizationId,
      }).catch((error) => {
        ToastError('Erreur', `Impossible de créer le mandat`);
        throw error;
      });

      onProMandateCreated?.();
      onClose?.();

      ToastSuccess('Succès', `Mandat créé`);
    },
    [proOrganizationId],
  );

  const initialValues: ProMandateFormFields = useMemo(() => {
    return {
      bic: '',
      iban: '',
      zohoOrganizationId: currentBilikPersonId === 10 ? 2 : 1,
    };
  }, [currentBilikPersonId]);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={mandateFormValidationSchema}
      validateOnChange={true}
    >
      <ProMandateFormView
        onClose={onClose}
        proOrganizationId={proOrganizationId}
        {...rest}
      />
    </Formik>
  );
};

export default ProMandateFormCreateContainer;
