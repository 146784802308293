import { Button, Grid, Icon } from 'semantic-ui-react';
import React, { FunctionComponent } from 'react';
import { BilikZonesTable } from './bilik-zones-table';
import { Link } from 'react-router-dom';
import Content from 'components/content/content';
import ContentHeader from 'components/content-header/content-header';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentBody from 'components/content-body/content-body';
import { useIsUserAdmin } from 'hooks/use-is-user-admin/use-is-user-admin';

const BilikZonesListPage: FunctionComponent = () => {
  const isCurrentUserAdmin = useIsUserAdmin();

  return (
    <Content>
      <ContentHeader>
        <Grid columns={2}>
          <Grid.Column>
            <ContentHeaderTitle>Zones</ContentHeaderTitle>
          </Grid.Column>
          <Grid.Column textAlign="right">
            {isCurrentUserAdmin && (
              <Link to="/bilik_zones/create">
                <Button positive>
                  <Icon name="plus" />
                  Nouveau
                </Button>
              </Link>
            )}
          </Grid.Column>
        </Grid>
      </ContentHeader>
      <ContentBody>
        <BilikZonesTable />
      </ContentBody>
    </Content>
  );
};

export default BilikZonesListPage;
