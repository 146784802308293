import React, { FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import TimelineEvent from '../timeline-event';
import { Icon, Label, Message } from 'semantic-ui-react';
import { getBilikUserName } from 'utils/get-bilik-username';

type ProReviewToggleSpamProps = {
  event: MongoEvent;
};

const ProReviewToggleSpamV1: FunctionComponent<ProReviewToggleSpamProps> = ({
  event,
}) => (
  <TimelineEvent
    date={event.date}
    icon="comment"
    title={
      event.data.proReview.isSpam
        ? 'Avis marqué comme spam'
        : 'Avis enlevé des spam'
    }
    color={event.data.proReview.isSpam ? 'red' : 'blue'}
    initiator="bilik-user"
    bilikUserName={getBilikUserName(event.initiator.bilik)}
  >
    <h3>
      Avis sur <span>{event.actors.pro.proPresentation.name}</span>{' '}
      {event.data.proReview.isSpam ? 'marqué' : 'enlevé'}{' '}
      {event.data.proReview.isSpam ? 'comme' : 'des'} spam
      {event.data.proReview.isSpam ? null : 's'}
    </h3>
    <section>
      <Label
        as="a"
        onClick={(): void => {
          window.open(
            `/pro_reviews?proReviewId=${event.data.proReview._id}&isAllZones=1`,
            '_blank',
          );
        }}
      >
        <Icon name="eye" size="small" />
        Voir avis
      </Label>
    </section>
  </TimelineEvent>
);

const ProReviewToggleSpamV2: FunctionComponent<ProReviewToggleSpamProps> = ({
  event,
}) => (
  <TimelineEvent
    date={event.date}
    icon="comment"
    title={
      event.data.proReview.isSpam
        ? 'Avis marqué comme spam'
        : 'Avis enlevé des spam'
    }
    color={event.data.proReview.isSpam ? 'red' : 'blue'}
    initiator="bilik-user"
    bilikUserName={getBilikUserName(event.initiator.bilik)}
  >
    <h3>
      Avis sur{' '}
      <span>
        {event.actors.pro.proPresentation.name} (
        {event.actors.pro.proPresentation.mainTrade?.name})
      </span>{' '}
      {event.data.proReview.isSpam ? 'marqué' : 'enlevé'}{' '}
      {event.data.proReview.isSpam ? 'comme' : 'des'} spam
      {event.data.proReview.isSpam ? null : 's'}
    </h3>
    <section>
      <Label
        as="a"
        onClick={(): void => {
          window.open(
            `/pro_reviews?proReviewId=${event.data.proReview._id}&isAllZones=1`,
            '_blank',
          );
        }}
      >
        <Icon name="eye" size="small" />
        Voir avis
      </Label>
    </section>
  </TimelineEvent>
);

const ProReviewToggleSpam: FunctionComponent<ProReviewToggleSpamProps> = ({
  event,
}) => {
  if (event.version === 1) {
    return <ProReviewToggleSpamV1 event={event} />;
  } else if (event.version === 2) {
    return <ProReviewToggleSpamV2 event={event} />;
  }
  return (
    <Message
      error
      content={`Pas d'event handler trouvé pour ProReviewToggleSpam (version ${event.version})`}
    />
  );
};

export default ProReviewToggleSpam;
