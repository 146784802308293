import { compareAsc, endOfMonth, startOfMonth } from 'date-fns';
import {
  ProViewBoolExp,
  useProViewPublishedCountByMonthQuery,
} from 'generated/graphql';
import { CountByMonth } from 'pages/dashboard/utils/count-by-month.type';
import { useEffect, useState } from 'react';

type ProViewPublishedCountByMonthProps = {
  months: Date[];
  where?: ProViewBoolExp;
};

export const useProViewPublishedCountByMonth = ({
  months,
  where,
}: ProViewPublishedCountByMonthProps): {
  loading: boolean;
  data: CountByMonth[];
} => {
  const [loading, setLoading] = useState(false);
  const [countByMonth, setCountByMonth] = useState<CountByMonth[]>([]);
  const { data } = useProViewPublishedCountByMonthQuery({
    variables: {
      where,
    },
  });

  useEffect(() => {
    if (!data) return;
    setLoading(true);

    const countByMonth: CountByMonth[] = [];

    months.forEach((month) => {
      const proViewCount = data.proView.filter(
        (proView) =>
          !!proView.datePublished &&
          new Date(proView.datePublished) < endOfMonth(month) &&
          (!proView.dateArchived ||
            new Date(proView.dateArchived) > startOfMonth(month)),
      ).length;

      countByMonth.push({
        month,
        count: proViewCount,
      });
    });

    setCountByMonth(countByMonth);
    setLoading(false);
  }, [data, months]);

  return {
    loading,
    data: countByMonth,
  };
};
