import React, { FunctionComponent, ReactElement } from 'react';
import { Label } from 'semantic-ui-react';

interface CallDetailsProps {
  duration: number;
  callRecordable?: boolean;
  tradeCallRecordable?: boolean;
  children?: ReactElement;
}

const CallDetails: FunctionComponent<CallDetailsProps> = ({
  duration,
  callRecordable,
  tradeCallRecordable,
  children,
}) => {
  // Care about undefined state
  if (tradeCallRecordable === false)
    return <Label color="orange">Métier sensible</Label>;

  if (callRecordable === false)
    return <Label color="orange">Enregistrement désactivé</Label>;

  if (duration <= 2) return <Label>Aucune conversation</Label>;

  return children ?? null;
};

export default CallDetails;
