import SelectProPresentationField from 'components/select-pro-presentation-field/select-pro-presentation-field';
import { Formik } from 'formik';
import React, { FunctionComponent, useCallback } from 'react';
import { Button, Form, Icon } from 'semantic-ui-react';
import { ToastError, ToastSuccess } from 'utils';
import * as Yup from 'yup';
import { ProReviewFormDuplicateFields } from './pro-review-duplicate-form-field';
type DuplicateProReviewFormContainerProps = {
  duplicateProReviewToProView: (toProViewId: number) => Promise<void>;
  proPresentationIds: number[];
  loading?: boolean;
};

export const DuplicateProReviewFormContainer: FunctionComponent<
  DuplicateProReviewFormContainerProps
> = ({ duplicateProReviewToProView, proPresentationIds, loading }) => {
  const onSubmit = useCallback(
    async (values?: ProReviewFormDuplicateFields) => {
      if (values) {
        try {
          await duplicateProReviewToProView(Number(values.proPresentationId));

          ToastSuccess('Succès', 'Avis dupliqué.');
        } catch (e) {
          ToastError('Erreur', 'Impossible de dupliquer');
        }
      }
    },
    [],
  );

  if (loading) {
    return <div>Chargement...</div>;
  }

  return (
    <Formik
      validationSchema={Yup.object().shape({
        proPresentationId: Yup.number().required('Fiche requise !'),
      })}
      initialValues={{ proPresentationId: null }}
      onSubmit={onSubmit}
      isInitialValid={true}
    >
      {(props): React.ReactElement => (
        <Form onReset={props.handleReset} onSubmit={props.handleSubmit}>
          <SelectProPresentationField
            name="proPresentationId"
            label="Choisir une fiche"
            helpText="Si la fiche est grisé, cela signifie que l'avis est déjà présent sur cette fiche."
            required
            selection
            filters={{
              disabledProPresentationIds: proPresentationIds,
            }}
            search
          />

          <Button
            primary
            type="submit"
            loading={props.isSubmitting}
            disabled={props.isSubmitting}
          >
            <Icon name="copy" />
            Dupliquer
          </Button>
        </Form>
      )}
    </Formik>
  );
};
