import React, { FunctionComponent, useCallback } from 'react';
import { ProsTableView } from './pros-table-view';
import { Me } from 'components/socket/socket';
import { Loader, Message } from 'semantic-ui-react';
import { StringParam, useQueryParam } from 'use-query-params';
import { ProsTableProView } from './pros-table-repository';
import { CurrentBilikZonesQuery } from 'generated/graphql';

type Props = {
  loading: boolean;
  proViews?: ProsTableProView[];
  currentBilikZones: CurrentBilikZonesQuery['bilikZone'] | null;
  orderBy;
  setOrderBy;
  setPage: (page: number) => void;
  membersByProViewId: { [key in string]: Me };
  selectedTradeId?: number | null;
};

export const ProsTableContainer: FunctionComponent<Props> = ({
  loading,
  orderBy,
  setOrderBy,
  proViews,
  currentBilikZones,
  setPage,
  membersByProViewId,
  selectedTradeId,
}) => {
  const [statusFilter] = useQueryParam('status', StringParam);

  const onClickSortByDate = useCallback((): void => {
    const newOrderBy = (column, direction): void => {
      switch (direction) {
        case 'ascending': {
          setOrderBy({
            column,
            direction: 'descending',
          });
          break;
        }
        case 'descending': {
          setOrderBy({
            column,
            direction: 'ascending',
          });
          break;
        }
      }
    };

    if (statusFilter === 'published') {
      newOrderBy('datePublished', orderBy.direction);
    } else if (statusFilter === 'archived') {
      newOrderBy('dateArchived', orderBy.direction);
    } else if (!statusFilter || statusFilter === 'draft') {
      newOrderBy('dateCreated', orderBy.direction);
    }

    setPage(1);
  }, [orderBy.direction, orderBy.column, statusFilter]);

  const onClickSortByRating = useCallback((): void => {
    switch (orderBy.direction) {
      case 'ascending': {
        setOrderBy({
          column: 'proPresentationRating',
          direction: 'descending',
        });
        break;
      }
      case 'descending': {
        setOrderBy({
          column: 'proPresentationRating',
          direction: 'ascending',
        });
        break;
      }
    }
    setPage(1);
  }, [orderBy.direction, orderBy.column]);

  const onClickSortByReviewCount = useCallback((): void => {
    switch (orderBy.direction) {
      case 'ascending': {
        setOrderBy({
          column: 'proPresentation.proReviews.aggregate.count',
          direction: 'descending',
        });
        break;
      }
      case 'descending': {
        setOrderBy({
          column: 'proPresentation.proReviews.aggregate.count',
          direction: 'ascending',
        });
        break;
      }
    }
    setPage(1);
  }, [orderBy.direction, orderBy.column]);

  const onClickSortByName = useCallback((): void => {
    switch (orderBy.direction) {
      case 'ascending': {
        setOrderBy({
          column: 'proPresentation.name',
          direction: 'descending',
        });
        break;
      }
      case 'descending': {
        setOrderBy({
          column: 'proPresentation.name',
          direction: 'ascending',
        });
        break;
      }
    }
    setPage(1);
  }, [orderBy.direction, orderBy.column]);

  if (!proViews) {
    return (
      <Loader
        style={{ marginTop: '50px' }}
        size="large"
        active
        inline="centered"
      >
        Chargement...
      </Loader>
    );
  }

  if (!proViews.length) {
    return <Message>Il n&apos;y a pas de résultats</Message>;
  }

  return (
    <ProsTableView
      loading={loading}
      orderBy={orderBy}
      statusFilter={statusFilter}
      onClickSortByDate={onClickSortByDate}
      onClickSortByRating={onClickSortByRating}
      onClickSortByName={onClickSortByName}
      onClickSortByReviewCount={onClickSortByReviewCount}
      currentBilikZones={currentBilikZones}
      proViews={proViews}
      membersByProViewId={membersByProViewId}
      selectedTradeId={selectedTradeId}
    />
  );
};
