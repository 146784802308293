import {
  Button,
  Label,
  Container,
  Grid,
  Icon,
  Popup,
  Loader,
  Pagination,
} from 'semantic-ui-react';
import {
  NumberParam,
  StringParam,
  withDefault,
  useQueryParams,
  ObjectParam,
} from 'use-query-params';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { ProPeopleTable } from './pro-people-table';
import { useCurrentBilikZones } from 'context/current-bilik-zones-context';
import { useProPeopleCount } from 'hooks/use-pro-people-count/use-pro-people-count';
import {
  ProPersonBoolExp,
  useExportProPeopleQuery,
  useProPeopleListPageQueryLazyQuery,
} from 'generated/graphql';
import { map, pipe, uniq } from 'remeda';
import { CSVLink } from 'react-csv';
import { ProPersonSearchFilter } from 'components/pro-person-search-filter';
import Content from 'components/content/content';
import ContentHeader from 'components/content-header/content-header';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentBody from 'components/content-body/content-body';
import { formatTelephone } from 'utils';

const ProPeopleListPage: FunctionComponent = () => {
  const { currentBilikZones } = useCurrentBilikZones();

  const currentBilikZoneIds = useMemo(
    () => currentBilikZones?.map((zone) => zone.id),
    [currentBilikZones],
  );

  const itemPerPage = useMemo(() => 50, []);

  const [filters, setFilters] = useQueryParams({
    page: NumberParam,
    proViewStatus: withDefault(StringParam, 'published'),
    proPersonId: NumberParam,
    orderBy: ObjectParam,
  });

  const where: ProPersonBoolExp = useMemo(
    () => ({
      id: filters?.proPersonId ? { _eq: filters.proPersonId } : undefined, // id filter
      proViews: {
        proView: {
          status: {
            _eq:
              filters.proViewStatus !== 'undefined'
                ? filters.proViewStatus
                : undefined,
          },
          bilikZoneId: { _in: currentBilikZoneIds ?? undefined },
        },
      },
      // Keep only proPeople that have an email or a telephone
      _or: [
        {
          _and: [
            { telephone: { _neq: '' } },
            { telephone: { _isNull: false } },
          ],
        },
        {
          _and: [{ email: { _isNull: false } }, { email: { _neq: '' } }],
        },
      ],
    }),
    [currentBilikZoneIds, filters?.proPersonId, filters?.proViewStatus],
  );

  const { data: exportProPeopleData } = useExportProPeopleQuery({
    variables: {
      where: where,
    },
  });

  const proPeopleCsvData = useMemo(() => {
    if (exportProPeopleData) {
      const data = pipe(
        exportProPeopleData?.proPerson,
        map((proPerson) => ({
          Nom: `${proPerson.familyName}`,
          Prénom: `${proPerson.givenName}`,
          Email: proPerson.email,
          Téléphone: proPerson.telephone
            ? formatTelephone(proPerson.telephone)
            : '',
        })),
        uniq(),
      );

      return data;
    }
  }, [exportProPeopleData]);

  const proPeopleEmailData = useMemo(() => {
    if (exportProPeopleData) {
      const data = pipe(
        exportProPeopleData?.proPerson,
        map((proPerson) => proPerson.email),
        uniq(),
      );

      return data;
    }
  }, [exportProPeopleData]);

  const [proPeopleListPageQuery, { data: proPeopleListPageQueryResult }] =
    useProPeopleListPageQueryLazyQuery();

  const currentProPerson = useMemo(
    () =>
      `${proPeopleListPageQueryResult?.proPersonByPk?.givenName} ${proPeopleListPageQueryResult?.proPersonByPk?.familyName}`,
    [proPeopleListPageQueryResult],
  );

  useEffect(() => {
    if (filters.proPersonId) {
      proPeopleListPageQuery({
        variables: {
          id: filters.proPersonId,
        },
      });
    }
  }, [filters.proPersonId]);

  const { count: totalCount } = useProPeopleCount(where);

  const { count: allCount, loading: allLoading } = useProPeopleCount({
    ...where,
    proViews: {
      proView: {
        ...where.proViews?.proView,
        status: undefined,
      },
    },
  });

  const { count: publishedCount, loading: publishedLoading } =
    useProPeopleCount({
      ...where,
      proViews: {
        proView: {
          ...where.proViews?.proView,
          status: { _eq: 'published' },
        },
      },
    });

  const { count: draftCount, loading: draftLoading } = useProPeopleCount({
    ...where,
    proViews: {
      proView: {
        ...where.proViews?.proView,
        status: { _eq: 'draft' },
      },
    },
  });

  const { count: archivedCount, loading: archivedLoading } = useProPeopleCount({
    ...where,
    proViews: {
      proView: {
        ...where.proViews?.proView,
        status: { _eq: 'archived' },
      },
    },
  });

  const { count } = useProPeopleCount({
    ...where,
    proViews: {
      proView: {
        ...where.proViews?.proView,
        status: {
          _eq:
            filters.proViewStatus !== 'undefined'
              ? filters.proViewStatus
              : undefined,
        },
      },
    },
  });

  const totalPages = useMemo<number>(
    () => Math.ceil((count || 0) / itemPerPage),
    [count],
  );

  if (currentBilikZones === undefined) {
    return (
      <Content>
        <Loader
          style={{ marginTop: '50px' }}
          size="large"
          active
          inline="centered"
        >
          Chargement...
        </Loader>
      </Content>
    );
  }

  return (
    <Content>
      <ContentHeader>
        <Grid columns={2}>
          <Grid.Column>
            <ContentHeaderTitle>
              Contacts pros
              <small>
                {currentBilikZones
                  ? currentBilikZones.map((zone) => zone.name).join(', ')
                  : 'Toutes les zones'}
              </small>
            </ContentHeaderTitle>
          </Grid.Column>
        </Grid>
      </ContentHeader>
      <ContentBody>
        <Grid>
          <Grid.Column width={14}>
            <Button.Group>
              <Button
                type="button"
                color="grey"
                loading={allLoading}
                disabled={allLoading}
                basic={filters.proViewStatus !== 'undefined'}
                onClick={(): void => {
                  setFilters({
                    proViewStatus: 'undefined',
                    page: undefined,
                  });
                }}
              >
                Tous ({allCount ?? 0})
              </Button>
              <Button
                type="button"
                color="green"
                loading={publishedLoading}
                disabled={publishedLoading}
                basic={filters.proViewStatus !== 'published'}
                onClick={(): void => {
                  setFilters({
                    proViewStatus: 'published',
                    page: undefined,
                  });
                }}
              >
                Fiches Publiées ({publishedCount})
              </Button>
              <Button
                type="button"
                color="red"
                loading={draftLoading}
                disabled={draftLoading}
                basic={filters.proViewStatus !== 'draft'}
                onClick={(): void => {
                  setFilters({
                    proViewStatus: 'draft',
                    page: undefined,
                  });
                }}
              >
                Fiches Brouillons ({draftCount})
              </Button>
              <Button
                type="button"
                color="grey"
                loading={archivedLoading}
                disabled={archivedLoading}
                basic={filters.proViewStatus !== 'archived'}
                onClick={(): void => {
                  setFilters({
                    proViewStatus: 'archived',
                    page: undefined,
                  });
                }}
              >
                Fiches Archivées ({archivedCount})
              </Button>
            </Button.Group>
            {filters.proPersonId ? (
              <Button
                as="div"
                style={{ marginLeft: '12px', marginRight: '0' }}
                labelPosition="right"
              >
                <Button
                  color="blue"
                  onClick={(): void => {
                    setFilters({ proPersonId: undefined, page: undefined });
                  }}
                >
                  <Icon name="delete" />
                </Button>
                <Label basic pointing="left">
                  {currentProPerson}
                </Label>
              </Button>
            ) : (
              <ProPersonSearchFilter
                onResultSelect={(event, { result }): void => {
                  setFilters({ proPersonId: result.value, page: undefined });
                }}
              />
            )}
          </Grid.Column>
          {exportProPeopleData ? (
            <Grid.Column width={2} textAlign="right">
              {currentBilikZoneIds ? (
                <Popup
                  position="top right"
                  trigger={
                    <span style={{ paddingTop: 7, paddingBottom: 7 }}>
                      <Button
                        as="a"
                        href={`mailto:?bcc=${proPeopleEmailData}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon name="mail" style={{ margin: 0 }} />
                      </Button>
                    </span>
                  }
                  content={`Envoyer un email aux (${totalCount}) contacts`}
                />
              ) : null}

              <Popup
                position="top right"
                trigger={
                  <CSVLink
                    className="button ui button"
                    filename={'contacts-pro.csv'}
                    data={proPeopleCsvData}
                  >
                    <Icon name="download" style={{ margin: 0 }} />
                  </CSVLink>
                }
                content={`Exporter les (${totalCount}) contacts en CSV`}
              />
            </Grid.Column>
          ) : null}
        </Grid>
        <ProPeopleTable
          where={where}
          orderBy={
            filters.orderBy?.column && filters.orderBy?.direction
              ? {
                  column: filters.orderBy?.column,
                  direction: filters.orderBy?.direction,
                }
              : undefined
          }
          onSort={(column, direction): void => {
            setFilters({
              orderBy: { column, direction },
            });
          }}
          limit={itemPerPage}
          offset={filters.page ? (filters.page - 1) * itemPerPage : 0}
        />
        {totalPages > 1 ? (
          <Container fluid textAlign="right" style={{ marginTop: '15px' }}>
            <Pagination
              activePage={filters.page ?? 1}
              totalPages={totalPages}
              onPageChange={(event, { activePage }): void => {
                setFilters({
                  page: Number(activePage),
                });
              }}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
            />
          </Container>
        ) : null}
      </ContentBody>
    </Content>
  );
};

export default ProPeopleListPage;
