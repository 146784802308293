import React, { FunctionComponent, useCallback } from 'react';
import SmsReceivedGraphContainer from './sms-received-graph-container';
import { endOfYear, startOfYear, subYears } from 'date-fns';
import { convertTableData } from '../utils/convert-table-data';
import { apiClient } from 'axios-client';
import { CountByMonth } from '../utils/count-by-month.type';

type SmsReceivedGraphRepositoryProps = {
  bilikZoneIds?: number[];
  proViewIds?: number[];
  year?: number;
  compareYear?: number;
};

const now = new Date();

const SmsReceivedGraphRepository: FunctionComponent<
  SmsReceivedGraphRepositoryProps
> = ({
  bilikZoneIds,
  proViewIds,
  year = now.getFullYear(),
  compareYear = subYears(now, 1).getFullYear(),
}) => {
  const getYearData = useCallback(
    async (year: number, proViewIds?: number[], bilikZoneIds?: number[]) => {
      const date = new Date(now);
      date.setFullYear(year);

      const result = await apiClient.post('event/bilik-sms-by-month', {
        after: startOfYear(date),
        before: endOfYear(date),
        bilikZoneIds,
        isFirstContactExcluded: true,
        isSpamExcluded: true,
        proViewIds,
      });

      const countByMonth: CountByMonth[] = result.data;

      return countByMonth;
    },
    [],
  );

  const fetchData = useCallback(async () => {
    const data: CountByMonth[] = [];

    if (compareYear !== year) {
      const compareYearData = await getYearData(
        compareYear,
        proViewIds,
        bilikZoneIds,
      );

      data.push(...compareYearData);
    }

    const yearData = await getYearData(year, proViewIds, bilikZoneIds);

    data.push(...yearData);

    return convertTableData(data, [year, compareYear]);
  }, [bilikZoneIds, proViewIds, year, compareYear]);

  return <SmsReceivedGraphContainer fetchData={fetchData} />;
};

export default SmsReceivedGraphRepository;
