import {
  ProReviewSetInput,
  ProReviewsTableQuery,
  useProReviewFormUpdateMutation,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';
import { ProReviewActionsModalContainer } from './pro-review-actions-modal-container';

type ProReviewActionsModalRepositoryProps = {
  modalMetadata: ReviewModalMetadata;
  closeModal: () => void;
  refetch: () => void;
};

export type ReviewModalMetadata = {
  isOpen: boolean;
  type?: 'refuse' | 'reply' | 'check' | 'sms' | 'duplicate' | 'reply-edit';
  proReview?: ProReview;
};

type Unpacked<T> = T extends (infer U)[] ? U : T;
type ProReview = Unpacked<ProReviewsTableQuery['proReview']>;

export const ProReviewActionsModalRepository: FunctionComponent<
  ProReviewActionsModalRepositoryProps
> = ({ modalMetadata, closeModal, refetch }) => {
  const [updateProReviewByPk] = useProReviewFormUpdateMutation();

  const updateProReview = useCallback(
    async (
      proReviewId: number,

      proReviewSetInput: ProReviewSetInput,
    ) => {
      await updateProReviewByPk({
        variables: {
          proReviewId: proReviewId,
          proReview: proReviewSetInput,
        },
      });

      refetch();
    },
    [updateProReviewByPk],
  );

  return (
    <ProReviewActionsModalContainer
      modalMetadata={modalMetadata}
      closeModal={closeModal}
      refetch={refetch}
      onUpdateProReview={updateProReview}
    />
  );
};
