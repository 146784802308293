import React, { FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import TimelineEvent from '../timeline-event';

type Props = {
  event: MongoEvent;
};

const FormContactBilik: FunctionComponent<Props> = ({ event }) => (
  <TimelineEvent
    date={event.date}
    icon="mail"
    title="Formulaire contact Bilik"
    initiator="particulier"
  >
    <h3>
      Le particulier a utilisé le formulaire de contact Bilik
      {event.data.contactBilik.bilikZone?.mainCityCode
        ? ` (${event.data.contactBilik.bilikZone?.mainCityCode})`
        : null}
    </h3>

    <section>
      <b>
        Message : <br />
      </b>
      {event.data.contactBilik.message}
    </section>
  </TimelineEvent>
);

export default FormContactBilik;
