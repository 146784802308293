import React, {
  createContext,
  useContext,
  useEffect,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useQuery } from '@apollo/client';
import {
  CurrentBilikZonesQuery,
  useCurrentBilikZonesLazyQuery,
} from 'generated/graphql';
import gql from 'graphql-tag';
import Client from 'client';

export type CurrentBilikZones = null | CurrentBilikZonesQuery['bilikZone'];

interface CurrentBilikZonesContextValue {
  currentBilikZones: CurrentBilikZones;
  loading: boolean;
  setCurrentBilikZones: (zoneIds: number[]) => void;
  addCurrentBilikZone: (zoneId: number) => void;
  removeCurrentBilikZone: (zoneId: number) => void;
}

const CurrentBilikZonesContext = createContext<
  CurrentBilikZonesContextValue | undefined
>(undefined);

export const CurrentBilikZonesProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [currentBilikZones, setCurrentBilikZonesState] =
    useState<CurrentBilikZones>(null);

  const setCurrentBilikZones = useCallback((zoneIds: number[]) => {
    Client.writeQuery({
      query: gql`
        query WriteZoneIds {
          zoneIds
        }
      `,
      data: { zoneIds },
    });

    localStorage.setItem('currentBilikZoneIds', JSON.stringify(zoneIds));
  }, []);

  const addCurrentBilikZone = useCallback(
    (zoneId: number) => {
      const zoneIds = JSON.parse(
        localStorage.getItem('currentBilikZoneIds') ?? '[]',
      );

      if (zoneIds.includes(0)) {
        const index = zoneIds.indexOf(0);
        if (index > -1) {
          zoneIds.splice(index, 1);
        }
      }

      zoneIds.push(zoneId);
      setCurrentBilikZones(zoneIds);
    },
    [setCurrentBilikZones],
  );

  const removeCurrentBilikZone = useCallback(
    (zoneId: number) => {
      const zoneIds = JSON.parse(
        localStorage.getItem('currentBilikZoneIds') ?? '[]',
      );
      const index = zoneIds.indexOf(zoneId);
      if (index > -1) {
        zoneIds.splice(index, 1);
      }
      setCurrentBilikZones(zoneIds);
    },
    [setCurrentBilikZones],
  );

  const { data: zoneData } = useQuery(gql`
    query {
      zoneIds @client
    }
  `);

  const localCurrentBilikZoneIds: number[] = useMemo(
    () => JSON.parse(localStorage.getItem('currentBilikZoneIds') ?? '[]') ?? [],
    [],
  );

  const [currentBilikZoneQuery, { data }] = useCurrentBilikZonesLazyQuery();

  useEffect(() => {
    setLoading(true);

    if (zoneData && zoneData.zoneIds) {
      currentBilikZoneQuery({ variables: { ids: zoneData.zoneIds } });
    } else if (!zoneData) {
      setCurrentBilikZones(localCurrentBilikZoneIds);
    }

    setLoading(false);
  }, [
    zoneData,
    localCurrentBilikZoneIds,
    currentBilikZoneQuery,
    setCurrentBilikZones,
  ]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data?.bilikZone?.length) {
      setCurrentBilikZonesState(data.bilikZone);
    } else {
      setCurrentBilikZonesState(null);
    }
  }, [data]);

  return (
    <CurrentBilikZonesContext.Provider
      value={{
        currentBilikZones,
        loading,
        setCurrentBilikZones,
        addCurrentBilikZone,
        removeCurrentBilikZone,
      }}
    >
      {children}
    </CurrentBilikZonesContext.Provider>
  );
};

export const useCurrentBilikZones = (): CurrentBilikZonesContextValue => {
  const context = useContext(CurrentBilikZonesContext);

  if (!context) {
    throw new Error(
      'useCurrentBilikZones must be used within a CurrentBilikZonesProvider',
    );
  }

  return context;
};
