import {
  Accordion,
  Icon,
  Input,
  Label,
  Menu,
  MenuHeader,
} from 'semantic-ui-react';
import React, { Fragment, FunctionComponent, useState } from 'react';
import { ToastError } from 'utils/toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SelectCurrentBilikZone } from 'components/select-current-bilik-zone';
import env from 'env';
import styled from 'styled-components';
import { useCurrentBilikPerson } from 'hooks/use-current-bilik-person/use-current-bilik-person';
import { useCurrentBilikZones } from 'context/current-bilik-zones-context';
import { useIsUserAdmin } from 'hooks/use-is-user-admin/use-is-user-admin';
import { useProReviewsCount } from 'hooks/use-pro-reviews-count/use-pro-reviews-count';
import { formatE164Telephone, isValidFrTelephone } from 'utils';

const height = window.innerHeight - 101;

const Wrapper = styled.aside`
  background-color: #222d32;
  position: absolute;
  top: 50px;
  left: 0;
  width: 230px;
  min-height: ${height}px;
  height: auto;

  /* Custom sidebar dropdown */
  .ui.selection.dropdown {
    background-color: #374850;
    border-radius: 4px;
    margin-right: 10px;
    margin-left: -8px;
    width: 100%;
    height: 37px;
    background-color: #fff;

    &.active,
    &:focus,
    &.active .text,
    &.active:hover .menu {
      border-color: #fff;
    }

    .text {
      vertical-align: middle;
    }

    .menu {
      border-color: transparent;
    }
  }

  /* Custom sidebar input */
  .ui.input {
    width: 100%;
    margin-right: 10px;
    margin-left: -8px;

    input {
      box-shadow: none;
      background-color: #374850;
      border: 1px solid transparent;
      height: 37px;
      color: #999;

      &:focus {
        color: #666;
        background-color: #fff;
      }
    }

    .icon {
      color: #999;
      margin-right: 5px !important;
    }
  }
`;

const Skin = styled.div`
  z-index: -1;
  background-color: #222d32;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 230px;
`;

const Profile = styled.div`
  position: relative;
  width: 100%;
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
`;

const AccordionItem = styled.li`
  text-decoration: none;
  color: #8aa4af;
  padding: 5px 5px 5px 25px;
  display: block;
  font-size: 14px;
  position: relative;

  .icon {
    line-height: 1;
    margin-right: 8px;
    font-size: 12px;
  }

  &:hover {
    text-decoration: none;
    color: white;
  }

  &.active {
    color: white;
  }
`;

const ProfileImage = styled.img`
  width: 100%;
  max-width: 45px;
  height: auto;
  border-radius: 50%;
  vertical-align: middle;
  float: left;
`;

const ProfileInfos = styled.div`
  color: #fff;
  padding: 5px 5px 5px 15px;
  line-height: 1;
  position: absolute;
  left: 55px;

  p {
    font-weight: 600;
    margin-bottom: 9px;
    line-height: 14px;
  }
`;

const StyledAccordion = styled(Accordion)`
  .title:not(.ui) {
    padding: 0 !important;

    .item {
      border-radius: 0 !important;

      i.icon:last-child {
        float: right;
        margin-top: 5px !important;
      }
    }
  }

  .active.title i.icon:last-child {
    transform: rotate(-90deg) !important;
  }
`;

const MainSidebar: FunctionComponent = () => {
  const location = useLocation();
  const history = useNavigate();

  const isCurrentUserAdmin = useIsUserAdmin();

  const [timelineInput, setTimelineInput] = useState({
    value: '',
    isClearable: false,
  });

  const [isTradesAccordionActive, setTradesAccordionActive] = useState<boolean>(
    location.pathname.startsWith('/trades') ||
      location.pathname.startsWith('/trades_guide'),
  );

  const timelineSearch = async (): Promise<void> => {
    const trimedTimelineInputValue = timelineInput.value.trim();
    let normalizedTimelineInputValue = trimedTimelineInputValue;

    // It's a telephone
    if (
      trimedTimelineInputValue.charAt(0) === '+' ||
      trimedTimelineInputValue.charAt(0) === '0' ||
      trimedTimelineInputValue.startsWith('33')
    ) {
      normalizedTimelineInputValue = trimedTimelineInputValue.replace(
        /\s/g,
        '',
      );

      if (!isValidFrTelephone(normalizedTimelineInputValue)) {
        return ToastError('Erreur', 'Numéro invalide !');
      }

      normalizedTimelineInputValue =
        formatE164Telephone(normalizedTimelineInputValue) ??
        normalizedTimelineInputValue;
    }

    setTimelineInput({
      value: normalizedTimelineInputValue,
      isClearable: true,
    });
    history(`/timeline/${encodeURIComponent(normalizedTimelineInputValue)}`);
  };

  const { currentBilikZones } = useCurrentBilikZones();
  const { currentBilikPerson } = useCurrentBilikPerson();

  const { loading: proReviewLoading, count: proReviewCount } =
    useProReviewsCount({
      // count proReview draft or replyStatus draft AND proReview not ko
      _or: [
        { status: { _eq: 'draft' } },
        {
          _and: [{ replyStatus: { _eq: 'draft' } }, { status: { _neq: 'ko' } }],
        },
      ],
      isSpam: { _eq: false },
      proPresentation: {
        proViews: {
          bilikZoneId: { _in: currentBilikZones?.map((zone) => zone.id) },
          status: { _in: ['published', 'draft'] },
        },
      },
    });

  return (
    <Fragment>
      <Wrapper>
        <Skin /> {/** Tricky background full height */}
        <Profile>
          <ProfileImage
            src={
              currentBilikPerson?.avatarHash
                ? `${env.API_URL}/images/${currentBilikPerson.avatarHash}/full/0,0/0/default.png`
                : '/lazy-white.png'
            }
            alt="Profile"
          />
          <ProfileInfos>
            <p>
              {currentBilikPerson?.givenName} {currentBilikPerson?.familyName}
            </p>
          </ProfileInfos>
        </Profile>
        <Menu fluid vertical secondary>
          <MenuHeader>Zones</MenuHeader>
          <Menu.Item>
            <SelectCurrentBilikZone allAuthorizedZones={true} />
          </Menu.Item>
          <MenuHeader>Timeline particulier</MenuHeader>
          <Menu.Item>
            <Input
              value={timelineInput.value}
              onKeyPress={(event): void => {
                if (event.key === 'Enter') {
                  timelineSearch();
                }
              }}
              onChange={(event, { value }): void =>
                setTimelineInput({ value, isClearable: false })
              }
              icon={
                timelineInput.isClearable ? (
                  <Icon
                    link
                    name="remove"
                    onClick={(): void =>
                      setTimelineInput({
                        value: '',
                        isClearable: false,
                      })
                    }
                  />
                ) : (
                  <Icon
                    link
                    name="search"
                    onClick={(): void => {
                      timelineSearch();
                    }}
                  />
                )
              }
              placeholder="Particulier..."
            />
          </Menu.Item>
          <MenuHeader>Navigation</MenuHeader>
          <Menu.Item
            as={Link}
            to="/pros?status=published&page=1"
            active={location.pathname.startsWith('/pros')}
          >
            <Icon name="user circle" />
            Professionnels
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/solicitations"
            active={location.pathname.startsWith('/solicitations')}
          >
            <Icon name="envelope" />
            Demandes par mail
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/sms-received"
            active={location.pathname.startsWith('/sms-received')}
          >
            <Icon name="comment alternate" />
            Demandes par sms
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/pro_reviews?isSpam=0"
            active={location.pathname.startsWith('/pro_reviews')}
          >
            <Icon name="comment outline" />
            Avis
            {proReviewCount ?? 0 > 0 ? (
              <Label color="red">
                {!proReviewLoading ? proReviewCount : '...'}
              </Label>
            ) : null}
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/calls"
            active={location.pathname.startsWith('/calls')}
          >
            <Icon name="call" />
            Appels
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/pro_people"
            active={location.pathname.startsWith('/pro_people')}
          >
            <Icon name="address book" />
            Contacts
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/dashboard"
            active={location.pathname.startsWith('/dashboard')}
          >
            <Icon name="chart line" />
            Dashboard
          </Menu.Item>
          <StyledAccordion>
            <Accordion.Title
              active={isTradesAccordionActive}
              onClick={(): void => {
                setTradesAccordionActive(!isTradesAccordionActive);
              }}
            >
              <Menu.Item
                active={
                  location.pathname.startsWith('/trades') ||
                  location.pathname.startsWith('/trades_guide')
                }
              >
                <Icon name="factory" />
                Catégories
                <Icon fitted name="chevron left" size="small" />
              </Menu.Item>
            </Accordion.Title>
            <Accordion.Content
              style={{ backgroundColor: '#2c3b41', padding: '0px' }}
              active={isTradesAccordionActive}
            >
              <AccordionItem
                as={Link}
                to="/trades"
                className={
                  location.pathname.startsWith('/trades') &&
                  !location.pathname.startsWith('/trades_guide') // Exception needed
                    ? 'active'
                    : undefined
                }
              >
                <Icon name="circle outline" />
                Web
              </AccordionItem>
              <AccordionItem
                as={Link}
                to="/trades_guide"
                className={
                  location.pathname.startsWith('/trades_guide')
                    ? 'active'
                    : undefined
                }
              >
                <Icon name="circle outline" />
                Guide
              </AccordionItem>
            </Accordion.Content>
          </StyledAccordion>
          <Menu.Item
            as={Link}
            to="/bilik_zones"
            active={location.pathname.startsWith('/bilik_zones')}
          >
            <Icon name="map marker alternate" />
            Zones
          </Menu.Item>
          {isCurrentUserAdmin ? (
            <Fragment>
              <MenuHeader>Admin</MenuHeader>
              <Menu.Item
                as={Link}
                to="/trades-info"
                active={location.pathname.startsWith('/trades-info')}
              >
                <Icon name="pie chart" />
                Répartition pros
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/bilik_people"
                active={location.pathname.startsWith('/bilik_people')}
              >
                <Icon name="user" />
                Utilisateurs
              </Menu.Item>

              <Menu.Item
                as={Link}
                to="/tools"
                active={location.pathname.startsWith('/tools')}
              >
                <Icon name="wrench" />
                Outils
              </Menu.Item>
            </Fragment>
          ) : null}
        </Menu>
      </Wrapper>
    </Fragment>
  );
};

export default MainSidebar;
