import React, { FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import TimelineEvent from '../timeline-event';
import { Icon, Label, Message } from 'semantic-ui-react';
import { getBilikUserName } from 'utils/get-bilik-username';

const ProReviewEditedV1: FunctionComponent<ProReviewEditedProps> = ({
  event,
}) => {
  return (
    <TimelineEvent
      date={event.date}
      icon="comment"
      title="Avis édité"
      initiator="bilik-user"
      bilikUserName={getBilikUserName(event.initiator.bilik)}
    >
      <h3>
        Avis sur <span>{event.actors.pro.proPresentation.name}</span> édité
      </h3>
      <section>
        <Label
          as="a"
          onClick={(): void => {
            window.open(
              `/pro_reviews?proReviewId=${event.data.proReview._id}&isAllZones=1`,
              '_blank',
            );
          }}
        >
          <Icon name="eye" size="small" />
          Voir avis
        </Label>
      </section>
    </TimelineEvent>
  );
};

const ProReviewEditedV2: FunctionComponent<ProReviewEditedProps> = ({
  event,
}) => (
  <TimelineEvent
    date={event.date}
    icon="comment"
    title="Avis édité"
    initiator="bilik-user"
    bilikUserName={getBilikUserName(event.initiator.bilik)}
  >
    <h3>
      Avis sur{' '}
      <span>
        {event.actors.pro.proPresentation.name} (
        {event.actors.pro.proPresentation.mainTrade?.name})
      </span>{' '}
      édité
    </h3>
    <div>
      <Label
        as="a"
        onClick={(): void => {
          window.open(
            `/pro_reviews?proReviewId=${event.data.proReview._id}&isAllZones=1`,
            '_blank',
          );
        }}
      >
        <Icon name="eye" size="small" />
        Voir avis
      </Label>
    </div>
  </TimelineEvent>
);

type ProReviewEditedProps = {
  event: MongoEvent;
};

const ProReviewEdited: FunctionComponent<ProReviewEditedProps> = ({
  event,
}) => {
  if (event.version === 1) {
    return <ProReviewEditedV1 event={event} />;
  } else if (event.version === 2) {
    return <ProReviewEditedV2 event={event} />;
  }
  return (
    <Message
      error
      content={`Pas d'event handler trouvé pour ProReviewEdited (version ${event.version})`}
    />
  );
};

export default ProReviewEdited;
