import { useState, useEffect } from 'react';
import { apiClient } from 'axios-client';
type SmsReceivedCounter = {
  count?: number;
  loading: boolean;
};

export type SmsReceivedCountFilters = {
  piTelephone?: string;
  proViewId?: number;
  proViewIds?: number[];
  after?: Date;
  before?: Date;
  isFirstContactExcluded?: boolean;
  isSpamExcluded?: boolean;
  bilikZoneId?: number;
  bilikZoneIds?: number[];
  isSpam?: boolean;
};

export const useSmsReceivedCount = (
  filters?: SmsReceivedCountFilters,
): SmsReceivedCounter => {
  const [count, setCount] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    apiClient
      .post<number>(`/event/pro-sms-count`, {
        ...filters,
      })
      .then((response) => {
        setCount(response.data);
        setLoading(false);
      });
  }, [
    filters?.piTelephone,
    filters?.proViewId,
    filters?.after,
    filters?.before,
    filters?.isFirstContactExcluded,
    filters?.isSpamExcluded,
    filters?.bilikZoneId,
    filters?.bilikZoneIds,
    filters?.proViewIds,
  ]);

  return {
    count,
    loading,
  };
};
