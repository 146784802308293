import FormField from 'components/form-field/form-field';
import { useFormikContext } from 'formik';
import { ProFormArchiveModalQuery } from 'generated/graphql';
import React, { FunctionComponent } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';

import {
  ProFormArchiveFields,
  ProFormArchiveModalProps,
} from './pro-form-archive-modal-repository';

type ProFormArchiveModalViewProps = ProFormArchiveModalProps & {
  otherProViews?: NonNullable<
    ProFormArchiveModalQuery['proOrganizationByPk']
  >['proPresentations'][0]['proViews'];
};

const ProFormArchiveModalView: FunctionComponent<
  ProFormArchiveModalViewProps
> = ({ otherProViews, open, onClose }) => {
  const { handleSubmit, handleReset, submitForm, values, isSubmitting } =
    useFormikContext<ProFormArchiveFields>();

  return (
    <Modal
      open={open}
      closeIcon
      closeOnDimmerClick={false}
      closeOnEscape={false}
      onClose={onClose}
      size={undefined}
    >
      <Modal.Header>Archiver</Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit} onReset={handleReset}>
          <p>Liste des actions provoquées par l&apos;archivage de la fiche :</p>
          <ul>
            <>
              <li>
                Un mail sera envoyé à tous les particuliers ayant un suivi
                actif.
              </li>
              <li>
                Un sms sera envoyé à tous les particuliers l&apos;ayant contacté
                les 4 derniers mois.
              </li>
              <li>
                Vous recevrez un mail listant les particuliers avec une ligne
                fixe à prévenir
              </li>
            </>
            <li>Tous les abonnements liés à cette fiche seront désactivés.</li>
            <li>
              Le numéro Call Tracking sera redirigé vers &quot;Pro plus dans
              Bilik&quot;.
            </li>
            <li>La fiche ne sera plus visible sur bilik.fr.</li>
            <li>
              Toutes les relances (mails, avis, demande d&apos;avis) seront
              désactivées.
            </li>
            <li>
              Les accès à l&apos;espace pro liès à cette fiche seront supprimés.
            </li>
            <li>La date d&apos;archive sera affectée à la date courante.</li>
            <li>
              La fiche sera enlevée des collegues sur lesquels elle était
              présente.
            </li>
          </ul>
          {otherProViews?.length ? (
            <FormField
              type="checkboxes"
              label="Archiver d'autres fiches simultanément ?"
              helpText="Les particuliers ne seront prévenus qu'une seule fois pour toutes les fiches archivées."
              name="proViewIds"
              options={otherProViews.map((proView) => ({
                key: proView.id,
                value: proView.id,
                label: `${proView.proPresentation.mainTrade?.name} - ${proView.bilikZone.name}`,
              }))}
            />
          ) : null}
          <p>
            {values.proViewIds.length > 1 ? (
              <strong>
                {values.proViewIds.length} fiches vont être archivées,{' '}
              </strong>
            ) : (
              <strong>La fiche va etre archivée, </strong>
            )}
            vous ne pourrez plus revenir en arrière, Continuer ?
          </p>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose}>
          Fermer
        </Button>

        <Button
          type="button"
          primary
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={submitForm}
        >
          <Icon name="save" />
          Archiver
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ProFormArchiveModalView;
