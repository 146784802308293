import { Button, Divider, Icon, Label, List, Modal } from 'semantic-ui-react';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { find } from 'remeda';
import { MongoEvent } from 'interfaces/events.interface';
import TimelineSmsAction from './timeline-sms-action';
import TimelineSmsDetails from './timeline-sms-details';
import { ToastError } from 'utils/toast';
import { apiClient } from 'axios-client';

type TimelineSmsPreviewProps = {
  messageId: string;
  title?: string;
};

const TimelineSmsPreview: FunctionComponent<TimelineSmsPreviewProps> = ({
  messageId,
  title,
}) => {
  const [isModalDetailOpen, setIsModalDetailOpen] = useState<boolean>(false);
  const [smsEvents, setSmsEvents] = useState<MongoEvent[]>([]);
  const [smsSentEvent, setSmsSentEvent] = useState<MongoEvent>();

  useEffect(() => {
    apiClient
      .get(`/event/sms-message-status/${messageId}`, {})
      .then((result) => {
        const data: MongoEvent[] = result.data;
        setSmsSentEvent(
          find(data, (sms: MongoEvent) => sms.type === 'SmsSent'),
        );
        setSmsEvents(data);
      })
      .catch((error) => {
        ToastError('Erreur', `HTTP Error ${error.message}`);
      });
  }, [messageId]);

  return (
    <>
      {title && <b>{title}</b>}
      {smsSentEvent ? (
        <Fragment>
          <Label
            as="a"
            onClick={(): void => {
              setIsModalDetailOpen(true);
            }}
          >
            <Icon name="info circle" size="small" />
            Détails
          </Label>
          <Modal
            open={isModalDetailOpen}
            onClose={(): void => {
              setIsModalDetailOpen(false);
            }}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            closeIcon
          >
            <Modal.Header>Détails</Modal.Header>
            <Modal.Content>
              <TimelineSmsDetails sms={smsSentEvent} />
              <Divider />
              <List>
                {smsEvents && smsEvents.length > 0
                  ? smsEvents.map((event) => {
                      if (event.data.vonage) {
                        switch (event.data.vonage.status) {
                          case 'sent':
                            return (
                              <TimelineSmsAction
                                key={event._id}
                                date={event.date}
                                text="Envoyé"
                                color="grey"
                                icon="mobile alternate"
                              />
                            );
                          case 'delivered':
                            return (
                              <TimelineSmsAction
                                key={event._id}
                                date={event.date}
                                text="Délivré"
                                color="green"
                                icon="mobile alternate"
                              />
                            );
                          case 'rejected':
                            return (
                              <TimelineSmsAction
                                key={event._id}
                                date={event.date}
                                text="Rejeté"
                                color="red"
                                icon="mobile alternate"
                              />
                            );
                          case 'undeliverable':
                            return (
                              <TimelineSmsAction
                                key={event._id}
                                date={event.date}
                                text="Impossible d'envoyer"
                                color="red"
                                icon="mobile alternate"
                              />
                            );

                          default:
                            return null;
                        }
                      }

                      if (event.data.twilio) {
                        switch (event.data.twilio.SmsStatus) {
                          case 'delivered':
                            return (
                              <TimelineSmsAction
                                key={event._id}
                                date={event.date}
                                text="Délivré"
                                color="green"
                                icon="mobile alternate"
                              />
                            );
                          case 'undelivered':
                            return (
                              <TimelineSmsAction
                                key={event._id}
                                date={event.date}
                                text="Non délivré"
                                color="red"
                                icon="mobile alternate"
                              />
                            );
                          case 'failed':
                            return (
                              <TimelineSmsAction
                                key={event._id}
                                date={event.date}
                                text="Erreur"
                                color="red"
                                icon="mobile alternate"
                              />
                            );
                          case 'sent':
                            return (
                              <TimelineSmsAction
                                key={event._id}
                                date={event.date}
                                text="Envoyé"
                                color="grey"
                                icon="mobile alternate"
                              />
                            );
                          case 'delivery_unknown':
                            return (
                              <TimelineSmsAction
                                key={event._id}
                                date={event.date}
                                text="Inconnu"
                                color="grey"
                                icon="mobile alternate"
                              />
                            );

                          default:
                            return null;
                        }
                      }

                      if (event.data.telnyx) {
                        switch (event.data.telnyx.to[0].status) {
                          case 'sent':
                            return (
                              <TimelineSmsAction
                                key={event._id}
                                date={event.date}
                                text="Envoyé"
                                color="grey"
                                icon="mobile alternate"
                              />
                            );
                          case 'delivered':
                            return (
                              <TimelineSmsAction
                                key={event._id}
                                date={event.date}
                                text="Délivré"
                                color="green"
                                icon="mobile alternate"
                              />
                            );
                          case 'sending_failed':
                          case 'delivery_failed':
                          case 'delivery_unconfirmed':
                            return (
                              <TimelineSmsAction
                                key={event._id}
                                date={event.date}
                                text={`Erreur (${event.data.telnyx.to[0].status})`}
                                color="red"
                                icon="mobile alternate"
                              />
                            );

                          default:
                            return null;
                        }
                      }
                    })
                  : "Pas d'activités enregistré"}
              </List>
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={(): void => {
                  setIsModalDetailOpen(false);
                }}
              >
                Fermer
              </Button>
            </Modal.Actions>
          </Modal>
        </Fragment>
      ) : null}
    </>
  );
};

export default TimelineSmsPreview;
