import {
  OrderBy,
  ProPersonBoolExp,
  useProPeopleTableQuery,
} from 'generated/graphql';
import React, { FunctionComponent } from 'react';
import { ProPeopleTableContainer } from './pro-people-table-container';

type ProPeopleTableRepository = {
  where?: ProPersonBoolExp;
  limit?: number;
  offset?: number;
  orderBy?: {
    column: string;
    direction: string;
  };
  onSort?: (column: string, direction: string) => void;
};

export const ProPeopleTableRepository: FunctionComponent<
  ProPeopleTableRepository
> = ({ limit, offset, orderBy, onSort, where }) => {
  const { loading, data } = useProPeopleTableQuery({
    variables: {
      where: where,
      limit,
      offset,
      orderBy: orderBy
        ? [
            {
              [orderBy.column]:
                orderBy.direction === 'ascending' ? OrderBy.Asc : OrderBy.Desc,
            },
          ]
        : undefined,
    },
  });

  return (
    <ProPeopleTableContainer
      loading={loading}
      proPeople={data?.proPerson}
      onSort={onSort}
      orderBy={orderBy}
    />
  );
};
