import { useProFormArchiveModalQuery } from 'generated/graphql';
import React, { FunctionComponent } from 'react';
import ProFormArchiveModalContainer from './pro-form-archive-modal-container';

type ProFormArchiveModalRepositoryProps = ProFormArchiveModalProps & {
  proViewId: number;
  proOrganizationId?: number;
  onProArchived?: (proViewId: number) => void;
};

export type ProFormArchiveModalProps = {
  open: boolean;
  onClose?: () => void;
};

export type ProFormArchiveFields = {
  proViewIds: number[];
};

export const ProFormArchiveModalRepository: FunctionComponent<
  ProFormArchiveModalRepositoryProps
> = ({ proViewId, proOrganizationId, ...rest }) => {
  const { data, loading } = useProFormArchiveModalQuery({
    variables: {
      proOrganizationId: proOrganizationId ?? 0,
      proViewId: proViewId,
    },
  });

  return (
    <ProFormArchiveModalContainer
      otherProViews={data?.proOrganizationByPk?.proPresentations.flatMap(
        ({ proViews }) => proViews,
      )}
      loading={loading}
      proViewId={proViewId}
      proOrganizationId={proOrganizationId}
      {...rest}
    />
  );
};
