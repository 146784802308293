import React, { FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';

type TimelineSmsDetailsProps = {
  sms: MongoEvent;
};

const TimelineSmsDetails: FunctionComponent<TimelineSmsDetailsProps> = ({
  sms,
}) => {
  if (sms.data.vonage) {
    return (
      <p>
        <b>De : </b> {sms.data.vonage.from} <br />
        <b>À : </b> {sms.data.vonage.to}
        <br />
        <b>ID Sms : </b> {sms.data.messageId}
      </p>
    );
  }

  if (sms.data.twilio) {
    return (
      <p>
        <b>De : </b> {sms.data.twilio.From} <br />
        <b>À : </b> {sms.data.twilio.To}
        <br />
        <b>ID Sms : </b> {sms.data.messageId}
      </p>
    );
  }

  if (sms.data.telnyx) {
    return (
      <p>
        <b>De : </b> {sms.data.telnyx.from.phone_number} <br />
        <b>À : </b> {sms.data.telnyx.to[0].phone_number}
        <br />
        <b>ID Sms : </b> {sms.data.telnyx.id}
      </p>
    );
  }

  return <>Pas de détails</>;
};

export default TimelineSmsDetails;
