import { Button, Icon, Label, Loader, Modal } from 'semantic-ui-react';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { ToastError } from 'utils/toast';
import { apiClient } from 'axios-client';
import TimelineFreshdeskConversations from './timeline-freshdesk-conversations';

type Props = {
  ticketId: number;
};

interface Conversation {
  id: number;
  created_at: string;
  updated_at: string;
  body: string;
  body_text: string;
  attachments?: any[];
  from_email?: string;
  to_emails?: string[];
}

export interface Ticket {
  id: number;
  created_at: string;
  updated_at: string;
  subject: string;
  from_email?: string;
  to_emails?: string[];
  cc_emails?: string[];
  fwd_emails?: string[];
  description?: string;
  conversations?: Conversation[];
  attachments?: any[];
}

const TimelineFreshdeskTicketPreview: FunctionComponent<Props> = ({
  ticketId,
}) => {
  const [contentModal, setContentModal] = useState<boolean>(false);
  const [ticket, setTicket] = useState<Ticket | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchFreshdeskTicket = useCallback(
    async (ticketId: number): Promise<void> => {
      try {
        const response = await apiClient.post(`/form/freshdesk/ticket`, {
          ticketId: ticketId,
        });

        // Map the response to a Conversation array
        const conversations: Conversation[] = response.data[1].map(
          (conversation) => {
            return {
              id: conversation.id,
              created_at: conversation.created_at,
              updated_at: conversation.updated_at,
              body: conversation.body,
              body_text: conversation.body_text,
              attachments: conversation.attachments,
              from_email: conversation.from_email,
              to_emails: conversation.to_emails,
            };
          },
        );

        // Get the description of the ticket (first message)
        const ticketDatas = response.data[0];

        const ticket: Ticket = {
          id: ticketDatas.id,
          created_at: ticketDatas.created_at,
          updated_at: ticketDatas.updated_at,
          subject: ticketDatas.subject,
          to_emails: ticketDatas.to_emails,
          from_email: ticketDatas.requester.email,
          cc_emails: ticketDatas.cc_emails,
          fwd_emails: ticketDatas.fwd_emails,
          description: ticketDatas.description,
          conversations: conversations,
          attachments: ticketDatas.attachments,
        };

        setContentModal(true);
        setTicket(ticket);
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        ToastError(
          'Erreur',
          error?.response?.status === 429
            ? `Impossible d'afficher le ticket pour le moment. Réessaye dans quelques minutes.`
            : 'Impossible de récupérer les conversations du ticket',
        );
      }
    },
    [],
  );

  return (
    <>
      <Label
        as="a"
        onClick={(): void => {
          setIsLoading(true);
          fetchFreshdeskTicket(ticketId);
        }}
      >
        {isLoading ? (
          <Loader active inline size="tiny" />
        ) : (
          <>
            <Icon name="eye" size="small" />
            <span>Aperçu</span>
          </>
        )}
      </Label>
      {ticket ? (
        <Modal
          open={contentModal}
          onClose={() => setContentModal(false)}
          closeOnDimmerClick={false}
          closeIcon
        >
          <Modal.Header>{ticket.subject}</Modal.Header>
          <Modal.Content>
            <TimelineFreshdeskConversations ticket={ticket} />
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={(): void => {
                setContentModal(false);
              }}
            >
              Fermer
            </Button>
          </Modal.Actions>
        </Modal>
      ) : null}
    </>
  );
};

export default TimelineFreshdeskTicketPreview;
