import React, { FunctionComponent } from 'react';
import { MongoEvent } from 'interfaces/events.interface';
import TimelineEvent from '../timeline-event';
import { Icon, Label, Message } from 'semantic-ui-react';
import { getBilikUserName } from 'utils/get-bilik-username';

const getActionText = (isAutorespondActive): string => {
  if (isAutorespondActive) {
    return 'activé';
  }
  return 'désactivé';
};

const ProReviewInvitationToggleAutorespondV1: FunctionComponent<
  ProReviewInvitationToggleAutorespondProps
> = ({ event }) => {
  const isAutorespondActive =
    event.data.proReviewInvitation.isAutorespondActive;

  const actionText = getActionText(isAutorespondActive);

  return (
    <TimelineEvent
      date={event.date}
      color={isAutorespondActive ? 'blue' : 'red'}
      icon="comment outline"
      title={
        isAutorespondActive
          ? 'Relance demande avis activée'
          : 'Relance demande avis désactivée'
      }
      initiator="bilik-user"
      bilikUserName={getBilikUserName(event.initiator.bilik)}
    >
      <h3>
        Relance demande avis sur{' '}
        <span>{event.actors.pro.proPresentation.name}</span> {actionText}
      </h3>
      <section>
        <Label
          as="a"
          onClick={(): void => {
            window.open(
              `/pros/update/${event.data.proReviewInvitation.proView._id}/review_invitations?proReviewInvitationId=${event.data.proReviewInvitation._id}`,
              '_blank',
            );
          }}
        >
          <Icon name="eye" size="small" />
          Voir avis
        </Label>
      </section>
    </TimelineEvent>
  );
};

const ProReviewInvitationToggleAutorespondV2: FunctionComponent<
  ProReviewInvitationToggleAutorespondProps
> = ({ event }) => {
  const isAutorespondActive =
    event.data.proReviewInvitation.isAutorespondActive;

  const actionText = getActionText(isAutorespondActive);
  const proView = event.data.proReviewInvitation.proView ?? undefined;

  return (
    <TimelineEvent
      date={event.date}
      icon="comment outline"
      title={
        isAutorespondActive
          ? 'Relance demande avis activée'
          : 'Relance demande avis désactivée'
      }
      color={isAutorespondActive ? 'blue' : 'red'}
      initiator="bilik-user"
      bilikUserName={getBilikUserName(event.initiator.bilik)}
    >
      <h3>
        Relance demande avis sur{' '}
        <span>
          {event.actors.pro.proPresentation.name} (
          {event.actors.pro.proPresentation.mainTrade?.name})
        </span>{' '}
        {actionText}
      </h3>
      {proView && (
        <section>
          <Label
            as="a"
            onClick={(): void => {
              window.open(
                `/pros/update/${proView._id}/review_invitations?proReviewInvitationId=${event.data.proReviewInvitation._id}`,
                '_blank',
              );
            }}
          >
            <Icon name="eye" size="small" />
            Voir avis
          </Label>
        </section>
      )}
    </TimelineEvent>
  );
};

type ProReviewInvitationToggleAutorespondProps = {
  event: MongoEvent;
};

const ProReviewInvitationToggleAutorespond: FunctionComponent<
  ProReviewInvitationToggleAutorespondProps
> = ({ event }) => {
  if (event.version === 1) {
    return <ProReviewInvitationToggleAutorespondV1 event={event} />;
  } else if (event.version === 2) {
    return <ProReviewInvitationToggleAutorespondV2 event={event} />;
  }
  return (
    <Message
      error
      content={`Pas d'event handler trouvé pour ProReviewInvitationToggleAutorespond (version ${event.version})`}
    />
  );
};

export default ProReviewInvitationToggleAutorespond;
