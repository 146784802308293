import { Icon, Label, Message } from 'semantic-ui-react';
import React, { Fragment, FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import TimelineEmailPreview from '../timeline-email-preview';
import TimelineEvent from '../timeline-event';
import { pathOr } from 'remeda';
import { ProReviewInvitationAutorespondToggle } from 'components/pro-review-invitation-autorespond-toggle/pro-review-invitation-autorespond-toggle';

const FromCreateProReviewInvitationV0: FunctionComponent<
  FormCreateProReviewInvitationProps
> = ({ event }) => {
  const isBilikInitiator = pathOr(
    event,
    ['initiator', 'bilik'] as unknown as [keyof MongoEvent],
    false,
  );

  return (
    <TimelineEvent
      date={event.date}
      icon="comment outline"
      title="Demande d'avis"
      initiator={isBilikInitiator ? 'bilik-user' : 'pro'}
    >
      <h3>
        <span>{event.actors.pro.proPresentation.name}</span> a réalisé une
        nouvelle demande d&apos;avis au particulier
        {isBilikInitiator ? (
          <Fragment>
            {' '}
            via{' '}
            <span>
              {event.initiator.bilik.givenName}{' '}
              {event.initiator.bilik.familyName}
            </span>
          </Fragment>
        ) : null}
      </h3>
      <section>
        <b>Relance :</b>
        <br />
        <ProReviewInvitationAutorespondToggle
          id={event.data.proReviewInvitation._id}
        />
      </section>
      <br />
      <br />
      <b>Email: </b>
      <Label color="yellow">
        <Icon name="times" size="small" />
        Non dispo (Old data)
      </Label>
    </TimelineEvent>
  );
};

const FromCreateProReviewInvitationV1: FunctionComponent<
  FormCreateProReviewInvitationProps
> = ({ event }) => {
  const isBilikInitiator = pathOr(
    event,
    ['initiator', 'bilik'] as unknown as [keyof MongoEvent],
    false,
  );
  return (
    <TimelineEvent
      date={event.date}
      icon="comment outline"
      title="Demande d'avis"
      initiator={isBilikInitiator ? 'bilik-user' : 'pro'}
    >
      <h3>
        <span>{event.actors.pro.proPresentation.name}</span> a réalisé une
        nouvelle demande d&apos;avis au particulier
        {isBilikInitiator ? (
          <Fragment>
            {' '}
            via{' '}
            <span>
              {event.initiator.bilik.givenName}{' '}
              {event.initiator.bilik.familyName}
            </span>
          </Fragment>
        ) : null}
      </h3>
      <section>
        <b>Relance :</b>
        <br />
        <ProReviewInvitationAutorespondToggle
          id={event.data.proReviewInvitation._id}
        />
      </section>
      <section>
        <TimelineEmailPreview
          eventDate={event.date}
          messageId={event.data.email?.messageId}
        />
      </section>
    </TimelineEvent>
  );
};

const FromCreateProReviewInvitationV2: FunctionComponent<
  FormCreateProReviewInvitationProps
> = ({ event }) => {
  const isBilikInitiator = pathOr(
    event,
    ['initiator', 'bilik'] as unknown as [keyof MongoEvent],
    false,
  );
  return (
    <TimelineEvent
      date={event.date}
      icon="comment outline"
      title="Nouvelle demande d'avis"
      initiator={isBilikInitiator ? 'bilik-user' : 'pro'}
    >
      <h3>
        <span>
          {event.actors.pro.proPresentation.name} (
          {event.actors.pro.proPresentation.mainTrade?.name})
        </span>{' '}
        a réalisé une nouvelle demande d&apos;avis au particulier
        {isBilikInitiator ? (
          <Fragment>
            {' '}
            via{' '}
            <span>
              {event.initiator.bilik.givenName}{' '}
              {event.initiator.bilik.familyName}
            </span>
          </Fragment>
        ) : null}
      </h3>
      <section>
        <b>Relance :</b>
        <br />
        <ProReviewInvitationAutorespondToggle
          id={event.data.proReviewInvitation._id}
        />
      </section>
      <section>
        <TimelineEmailPreview
          eventDate={event.date}
          messageId={event.data.email?.messageId}
        />
      </section>
    </TimelineEvent>
  );
};

type FormCreateProReviewInvitationProps = {
  event: MongoEvent;
};

const FormCreateProReviewInvitation: FunctionComponent<
  FormCreateProReviewInvitationProps
> = ({ event }) => {
  if (event.version === 0) {
    return <FromCreateProReviewInvitationV0 event={event} />;
  } else if (event.version === 1) {
    return <FromCreateProReviewInvitationV1 event={event} />;
  } else if (event.version === 2) {
    return <FromCreateProReviewInvitationV2 event={event} />;
  }

  return (
    <Message
      error
      content={`Pas d'event handler trouvé pour FormCreateProReviewInvitation (version ${event.version})`}
    />
  );
};

export default FormCreateProReviewInvitation;
