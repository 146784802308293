import React, { FunctionComponent } from 'react';

import { FreshdeskEvent } from 'interfaces/events.interface';
import TimelineEvent from '../timeline-event';
import TimelineFreshdeskTicketPreview from '../timeline-freshdesk-ticket-preview';
import { formatDateTime } from 'utils';

type FreshdeskTicketProps = {
  event: FreshdeskEvent;
};

const FreshdeskTicket: FunctionComponent<FreshdeskTicketProps> = ({
  event,
}) => {
  return (
    <TimelineEvent date={event.date} icon="ticket alternate" title="Freshdesk">
      <h3>
        Ticket Freshdesk : <i>{event.ticket.subject}</i>
      </h3>

      <section>
        <TimelineFreshdeskTicketPreview ticketId={event.ticket.id} />
      </section>

      <span className="right">
        Dernier échange le : {formatDateTime(event.ticket.updated_at)}
      </span>
    </TimelineEvent>
  );
};

export default FreshdeskTicket;
