import React, { FunctionComponent, useState } from 'react';
import {
  Button,
  Checkbox,
  Grid,
  Modal,
  FormField as FormFieldUi,
  Form,
  Dropdown,
} from 'semantic-ui-react';
import { ToastError, ToastInfo } from 'utils/toast';
import ManageProsContainer, {
  ManageProViewContainerItem,
} from './manage-pros/manage-pros';
import { SelectCurrentBilikZone } from 'components/select-current-bilik-zone';
import Content from 'components/content/content';
import ContentHeader from 'components/content-header/content-header';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentBody from 'components/content-body/content-body';
import { apiClient } from 'axios-client';
import { SelectBilikZone } from 'components/select-bilik-zone/select-bilik-zone';

const ExportGuidePage: FunctionComponent = () => {
  const [selectedBilikZoneId, setSelectedBilikZoneId] = useState<number>();
  const [displayGenerateButton, setDisplayGenerateButton] =
    useState<boolean>(false);
  const [proItems, setProItems] = useState<ManageProViewContainerItem[]>([]);
  const [countKey, setCountKey] = useState<number>(0);

  const [generateModal, setGenerateModal] = useState<{ isOpen: boolean }>({
    isOpen: false,
  });
  const [selectedSeason, setSelectedSeason] = useState<string>();

  const generateGuide = async (season: string, year: number): Promise<void> => {
    await apiClient
      .post(`/form/export-guide/`, {
        proViewIds: proItems.reduce<number[]>((acc, proItem) => {
          if (proItem.isChecked) {
            return acc;
          }
          acc.push(proItem.id);
          return acc;
        }, []),
        bilikZoneId: selectedBilikZoneId,
        edition: {
          season,
          year,
        },
      })
      .catch((error) => {
        if (error.status === 'Request failed with status code 504') {
          ToastInfo(
            'Info',
            'Request timeout, Génération du guide toujours en cours...',
          );
        } else {
          ToastError('Erreur', 'Impossible de générer le guide');
          throw error;
        }
      });

    setGenerateModal({ isOpen: false });
    setSelectedSeason(undefined);

    ToastInfo(
      'Info',
      "Génération du guide en cours, l'archive sera disponible sur aws ",
    );
    setDisplayGenerateButton(false);
  };

  const countActivePro = (): number => {
    return proItems.reduce(
      (acc, proView) => (proView.isChecked ? acc : acc + 1),
      0,
    );
  };

  return (
    <Content>
      <ContentHeader>
        <ContentHeaderTitle>Guide</ContentHeaderTitle>
      </ContentHeader>
      <ContentBody>
        <Grid columns={2}>
          <Grid.Column>
            <SelectBilikZone
              onChange={(bilikZoneId): void => {
                setSelectedBilikZoneId(bilikZoneId);
                setDisplayGenerateButton(false);
                setProItems([]);
              }}
              value={selectedBilikZoneId}
            />
          </Grid.Column>
          <Grid.Column textAlign="right">
            {displayGenerateButton ? (
              <div>
                <Button
                  onClick={(): void => {
                    setCountKey(countKey + 1);
                    setProItems([]);
                  }}
                >
                  Randomiser à nouveau
                </Button>
                <Button
                  primary
                  disabled={!displayGenerateButton}
                  onClick={(): void => {
                    setGenerateModal({ isOpen: true });
                  }}
                >
                  Générer le guide ({countActivePro()} pros)
                </Button>
              </div>
            ) : null}
          </Grid.Column>
        </Grid>

        {selectedBilikZoneId ? (
          <>
            <ManageProsContainer
              bilikZoneId={selectedBilikZoneId}
              key={countKey} // To refresh component to random again
              proViewItems={proItems}
              onItemChange={(items): void => {
                setProItems(items);
                setDisplayGenerateButton(true);
              }}
            />
            <Modal open={generateModal.isOpen}>
              <Modal.Header>Quelle édition ?</Modal.Header>
              <Modal.Content>
                <Form>
                  <FormFieldUi>
                    <Dropdown
                      selection
                      onChange={(_, { value }): void => {
                        setSelectedSeason(value as string);
                      }}
                      options={[
                        { key: 'summer', value: 'été', text: 'Eté' },
                        {
                          key: 'spring',
                          value: 'printemps',
                          text: 'Printemps',
                        },
                        { key: 'autumn', value: 'automne', text: 'Automne' },
                      ]}
                      placeholder={'Sélectionnez une saison...'}
                    />
                  </FormFieldUi>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  disabled={!selectedSeason}
                  primary
                  onClick={(): void => {
                    generateGuide(
                      selectedSeason ? selectedSeason : '',
                      new Date().getFullYear(),
                    );
                  }}
                >
                  Générer le guide
                </Button>
              </Modal.Actions>
            </Modal>
          </>
        ) : null}
      </ContentBody>
    </Content>
  );
};

export default ExportGuidePage;
