import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { OrderBy, TradesTableQuery } from 'generated/graphql';
import { Loader, Message } from 'semantic-ui-react';
import { TradesTableView } from './trades-tables-view';

type Props = {
  loading: boolean;
  trades: TradesTableQuery['trade'] | [];
  tradesBis: TradesTableQuery['trade'] | [];
  orderBy: {
    column: string;
    direction: string;
  };
  onChangeBilikZoneBis?: (bilikZoneId: number) => void;
  onSort?: (column: string, direction: string) => void;
  refetch: () => Promise<void>;
  refetchBis: () => Promise<void>;
};
export type Trade = TradesTableQuery['trade'][0] & {
  totalProCount: number;
  totalProsCountBis: number;
  totalMainAndSecondaryTradeProCount: number;
  totalMainAndSecondaryTradeProCountBis: number;
};

export const TradesTableContainer: FunctionComponent<Props> = ({
  loading,
  trades,
  tradesBis,
  refetch,
  refetchBis,
  orderBy,
  onSort,
}) => {
  const [loadingBis, setLoadingBis] = useState(false);
  const direction = useMemo(() => {
    switch (orderBy.direction) {
      case 'ascending': {
        return OrderBy.Asc;
      }
      case 'descending': {
        return OrderBy.Desc;
      }
    }
  }, [orderBy.direction]);

  const getMainAndSecondaryTradeProCount = (
    trade: TradesTableQuery['trade'][0],
  ) => {
    const mainTradeProCount = trade.mainTradeProPresentation.reduce(
      (acc, proPresentation) =>
        (acc += proPresentation.proViewsAggregate.aggregate?.count || 0),
      0,
    );

    const secondaryTradeProCount = trade.secondaryTradeProPresentation.reduce(
      (acc, proPresentation) =>
        (acc += proPresentation.proViewsAggregate.aggregate?.count || 0),
      0,
    );

    return mainTradeProCount + secondaryTradeProCount;
  };

  useEffect(() => {
    const fetch = async () => {
      setLoadingBis(true);
      await refetch();
      await refetchBis();
      setLoadingBis(false);
    };
    fetch();
  }, [refetch, refetchBis]);

  const tradesFormatted: Trade[] = trades.map(
    (trade: TradesTableQuery['trade'][0]) => {
      return {
        ...trade,
        totalProCount: trade.tradeProViewsAggregate.aggregate?.count ?? 0,
        totalProsCountBis:
          tradesBis?.find((tradeBis) => tradeBis.id === trade.id)
            ?.tradeProViewsAggregate.aggregate?.count ?? 0,
        totalMainAndSecondaryTradeProCount:
          getMainAndSecondaryTradeProCount(trade) ?? 0,
        totalMainAndSecondaryTradeProCountBis:
          getMainAndSecondaryTradeProCount(
            tradesBis?.find((tradeBis) => tradeBis.id === trade.id) ?? trade,
          ) ?? 0,
      };
    },
  );
  const [sortedData, setSortedData] = useState<Trade[]>(tradesFormatted);

  useEffect(() => {
    const sortTrades = (trades: Trade[]) => {
      return [...trades].sort((a, b) => {
        switch (orderBy.column) {
          case 'tradeProViews':
            const aCount = a.totalProCount ?? 0;
            const bCount = b.totalProCount ?? 0;
            return (
              (direction === OrderBy.Asc ? aCount - bCount : bCount - aCount) ??
              0
            );
          case 'tradeProViewsBis':
            const aCountBis = a.totalProsCountBis ?? 0;
            const bCountBis = b.totalProsCountBis ?? 0;
            return (
              (direction === OrderBy.Asc
                ? aCountBis - bCountBis
                : bCountBis - aCountBis) ?? 0
            );
          case 'tradeProViewsInMainTradeAndSecondaryTrade':
            const aTotalMainAndSecondaryTradeProCount =
              a.totalMainAndSecondaryTradeProCount ?? 0;

            const bTotalMainAndSecondaryTradeProCount =
              b.totalMainAndSecondaryTradeProCount ?? 0;

            return (
              (direction === OrderBy.Asc
                ? aTotalMainAndSecondaryTradeProCount -
                  bTotalMainAndSecondaryTradeProCount
                : bTotalMainAndSecondaryTradeProCount -
                  aTotalMainAndSecondaryTradeProCount) ?? 0
            );
          case 'tradeProViewsInMainTradeAndSecondaryTradeBis':
            const aTotalMainAndSecondaryTradeProCountBis =
              a.totalMainAndSecondaryTradeProCountBis ?? 0;

            const bTotalMainAndSecondaryTradeProCountBis =
              b.totalMainAndSecondaryTradeProCountBis ?? 0;

            return (
              (direction === OrderBy.Asc
                ? aTotalMainAndSecondaryTradeProCountBis -
                  bTotalMainAndSecondaryTradeProCountBis
                : bTotalMainAndSecondaryTradeProCountBis -
                  aTotalMainAndSecondaryTradeProCountBis) ?? 0
            );
          case 'name':
            return (
              (direction === OrderBy.Asc
                ? a.name.localeCompare(b.name)
                : b.name.localeCompare(a.name)) ?? 0
            );
          case 'tradeGroups':
            const aTradeName = a.tradeGroups[0]?.tradeGroup.name ?? '';
            const bTradeName = b.tradeGroups[0]?.tradeGroup.name ?? '';

            return (
              (direction === OrderBy.Asc
                ? aTradeName.localeCompare(bTradeName)
                : bTradeName.localeCompare(aTradeName)) ?? 0
            );
          default:
            return 0;
        }
      });
    };

    const sortedTrades = sortTrades(tradesFormatted);

    setSortedData(sortedTrades);
  }, [tradesBis, trades, orderBy, direction]);

  if (!loading && !trades.length) {
    return <Message>Il n&apos;y a pas de résultats</Message>;
  }

  if ((loading || loadingBis) && !trades.length) {
    return (
      <Loader
        style={{ marginTop: '50px' }}
        size="large"
        active
        inline="centered"
      >
        Chargement...
      </Loader>
    );
  }

  return (
    <TradesTableView
      loading={loading}
      onSort={onSort}
      orderBy={orderBy}
      refetch={refetch}
      trades={sortedData}
    />
  );
};
