import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import { ToastContainer } from 'react-toastify';
import AppLayout from 'components/app-layout/app-layout';
import Client from 'client';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { Loader } from 'semantic-ui-react';
import {
  isAuthorizedRoles,
  keycloak,
  setRefreshToken,
  setToken,
  setTokenExp,
} from './utils';
import styled from 'styled-components';
import env from 'env';
import { Crisp } from 'crisp-sdk-web';
import { apiClient } from 'axios-client';

const envColor = (): string => {
  switch (env.ENVIRONMENT) {
    case 'development':
      return 'green !important';
    case 'e2e':
      return 'fuchsia !important';
    case 'staging':
      return 'orange !important';
    default:
      return '#357ca5';
  }
};

const envName = (): string => {
  switch (env.ENVIRONMENT) {
    case 'development':
      return 'Development';
    case 'e2e':
      return 'E2e';
    case 'staging':
      return 'Staging';
    default:
      return '';
  }
};

const EnvBanner = styled.div`
  display: block;
  height: 50px;
  position: sticky;
  background: ${envColor()};
  top: 0;
  left: 0;
  z-index: 5000;

  &:before {
    content: '${envName()}';
    color: #fff;
    line-height: 50px;
    vertical-align: middle;
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
    text-align: center;
  }
`;

const App: FunctionComponent = () => {
  const handleAuthentication = useCallback(() => {
    if (!keycloak.authenticated) {
      keycloak.login();
    }
  }, [keycloak.authenticated]);

  useEffect(() => {
    if (!Crisp.isCrispInjected()) {
      Crisp.configure('b2a1ccab-c53a-49b3-813b-7b1a5a9bedfe', {
        autoload: false,
      });
    }
  }, []);

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onEvent={async (event): Promise<void> => {
        if (event === 'onReady') {
          handleAuthentication();
        }

        if (
          event === 'onAuthSuccess' &&
          keycloak.token &&
          keycloak.refreshToken &&
          keycloak.tokenParsed &&
          keycloak.tokenParsed.exp
        ) {
          // Check roles
          if (!isAuthorizedRoles(keycloak.tokenParsed)) {
            console.error('Unauthorized roles');
            keycloak.logout();
            return;
          }

          setToken(keycloak.token);
          setRefreshToken(keycloak.refreshToken);
          setTokenExp(keycloak.tokenParsed.exp);

          // Init crisp
          if (env.ENVIRONMENT === 'production') {
            apiClient
              .post<{ email: string; signature: string }>(
                `${env.API_URL}/form/crisp/sign-email`,
              )
              .then(({ data }) => {
                Crisp.user.setEmail(data.email, data.signature);
                Crisp.chat.show();
              });
          }
        }
      }}
      LoadingComponent={<Loader />}
    >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        pauseOnHover
        hideProgressBar
      />
      <ApolloProvider client={Client}>
        {env.ENVIRONMENT !== 'production' ? <EnvBanner /> : null}
        <AppLayout />
      </ApolloProvider>
    </ReactKeycloakProvider>
  );
};
export default App;
